import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.919 0.5H1.08104C0.495131 0.5 0 0.995131 0 1.58104V49.419C0 49.9966 0.503383 50.5 1.08104 50.5H48.919C49.5049 50.5 50 49.9966 50 49.419V1.58104C49.9175 0.995131 49.4141 0.5 48.919 0.5ZM47.8379 9.89924H11.4788V2.57955H47.8379V9.89924ZM9.39924 2.57955V9.89924H2.07955V2.57955H9.39924ZM2.07955 11.9788H9.39924V48.3379H2.07955V11.9788ZM11.4788 48.3379V11.9788H47.8379V48.3379H11.4788Z" fill="#000000"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
