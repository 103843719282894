import Icon from "@ant-design/icons";


const Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="24" transform="matrix(-1 0 0 1 48 0)" fill="#D7B56D"/>
    <path
      d="M32.8333 31H15.1667C14.525 31 14 30.475 14 29.8333C14 29.1917 14.525 28.6667 15.1667 28.6667H32.8333C33.475 28.6667 34 29.1917 34 29.8333C34 30.475 33.475 31 32.8333 31ZM32.8333 25.1667H15.1667C14.525 25.1667 14 24.6417 14 24C14 23.3583 14.525 22.8333 15.1667 22.8333H32.8333C33.475 22.8333 34 23.3583 34 24C34 24.6417 33.475 25.1667 32.8333 25.1667ZM34 18.1667C34 18.8083 33.475 19.3333 32.8333 19.3333H15.1667C14.525 19.3333 14 18.8083 14 18.1667C14 17.525 14.525 17 15.1667 17H32.8333C33.475 17 34 17.525 34 18.1667Z"
      fill="#1D1D1F"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
