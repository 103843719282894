import styled from 'styled-components'

export const StyledSection4Container = styled.div`
  margin-top: 40px;
  margin-bottom: 220px;
  
  @media(max-width: 450px){
    margin-bottom: 150px;
  }
  
  @media(max-width: 1534px){
    .custom-br{
      display: none;
    }
  }

  @media(max-width: 350px){
    svg{
      width: 130px !important;
    }
    
    
    .ourServiceTitle, .anticon{
      position: relative;
      left: -1px;
    }
  }
    
  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;
      
      @media (max-width: 495px) {
          margin-top: 30px !important;
          margin-bottom: 40px !important;
      }
    
    .title{
      font-size: 48px;
      line-height: 56px;
      font-weight: bold;

      @media(max-width: 862px){
        font-size: 40px;
        line-height: 48px;
      }

      @media(max-width: 450px){
        font-size: 32px;
        line-height: 40px;
      }
    }
    
    
    
    .subtitle{
      font-size: 20px;
      line-height: 40px;
      text-align: center;

      @media(max-width: 862px){
        font-size: 16px;
        line-height: 24px;
      }

      @media(max-width: 450px){
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  
  .ourServiceContainer{
    position: relative;
    
    @media(max-width: 768px){
      margin-bottom: 40px;
    }
  }
  
  .ourServiceTitle{
    color: #000;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    @media (max-width: 450px){
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
`