import React from "react";
import {Col, Flex, Image, Row, Grid} from "antd";
import {StyledSection2Container} from "./Section2.styles";
import {
    CouponOffAnyService,
    CouponSeniorDiscount,
    psaBadge,
    uasaBadge,
    homeAdvisorBadge,
    BillIcon,
    WarrantyIcon,
    LicensedIcon,
    CouponOffAnyServiceAdaptive,
    CouponSeniorDiscountAdaptive,
    CouponOffAnyServiceMobile, CouponSeniorDiscountMobile
} from "../../../../assets";
import {MyButton} from "../../../common/MyButton/MyButton";
import {PaymentSection} from "./PaymentSection/PaymentSection";

const {useBreakpoint} = Grid;
export const Section2 = ({setIsModalOpen}) => {

    const screens = useBreakpoint();

    const advantages = [
        {
            image: <BillIcon/>,
            title: "Transparancy",
            content: <span>We will diagnose the issue with the appliance and provide an estimate for <span className="bold-text">$90 fixed service fee.</span>
                Call us now and get the best value for your money.</span>
        },
        {
            image: <WarrantyIcon/>,
            title: "Complete Warranty",
            content: <span>Our team offers a 1-year warranty on all services. Trust our experts for reliable repairs that last. All repairs are guaranteed! <span
                className="bold-text">You can be sure of the end result.</span></span>
        },
        {
            image: <LicensedIcon/>,
            title: "Licensed Technicians",
            content: <span>Our technicians can work on home appliances  of any type and manufacturer. They <span
                className="bold-text">receive regular factory trainings</span> and can repair both gas and electric appliances.</span>
        },
    ]

    return (
        <StyledSection2Container>
            <Col style={{marginTop: 40}} xs={{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{flexDirection: "column", marginTop: 70, marginBottom: 60}}
                     align="middle">
                    <h1 className="title">About us</h1>
                </Row>
                {!screens.sm ? (
                    <Row style={{flexDirection: "column"}}>
                        {advantages.map((item, index) => (
                            <Col key={item.title + "-" + index} span={24} style={{marginBottom: 24}}>
                                <Row style={{flexDirection: "column"}} align="middle">
                                    {item.image}
                                    <h3 className="advantagesTitle">{item.title}</h3>
                                    <span className="advantagesText" style={{textAlign: "center"}}>{item.content}</span>
                                </Row>
                            </Col>
                        ))
                        }
                    </Row>
                ) : (
                    <Flex justify="space-between" className="advantagesItemsContainer">
                        {advantages.map((item, index) => (
                            <Col key={index + "-section2-item"} span={7}>
                                <Row style={{flexDirection: "column"}} align="middle">
                                    {item.image}
                                    <h3 className="advantagesTitle">{item.title}</h3>
                                    <span className="advantagesText" style={{textAlign: "center"}}>{item.content}</span>
                                </Row>
                            </Col>
                        ))}
                    </Flex>
                )}

                <Row className="scheduleAppointmentContainer" justify="center">
                    <MyButton type="primary" onClick={() => setIsModalOpen(true)}>Schedule Appointment</MyButton>
                </Row>
            </Col>
        </StyledSection2Container>
    )
}