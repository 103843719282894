import styled from 'styled-components'


import bg from '../../../../assets/images/section5/newSection5Img.png'
import bg1440 from '../../../../assets/images/section5/1440.png'
import bg768 from '../../../../assets/images/section5/768.png'
import bg375 from '../../../../assets/images/section5/375.png'


export const StyledSection5Container = styled.div`
    background-image: url(${bg});
    background-color: #000;
    background-repeat: no-repeat;
    background-position: top;

    @media (min-width:1920px) {
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (max-width: 1200px) {
        background-image: url(${bg1440});
    }
    
    @media (max-width: 991px) {
        background-image: url(${bg1440});
        background-position: top left;
    }

    @media (max-width: 696px) {
        background-image: url(${bg768});
    }

    @media (max-width: 375px) {
        background-image: url(${bg375});
        background-position: top left;
    }
    
  .textBlock{
    flex-direction: column;
    padding-top: 90px;
    margin-bottom: 60px;
    
    .title{
      color: #fff;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;
      
      @media (max-width: 768px){
        font-size: 40px;
      }
      
      @media (max-width: 450px){
        font-size: 32px;
        line-height: 48px;
      }
    }
    
    .subtitle{
      color: #fff;
      font-size: 20px;
      line-height: 40px;
      text-align: center;

      @media (max-width: 450px){
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  
  .ant-steps{
    position: relative;
    left: 60px;
    &-item{
      
      .ant-steps-item-tail:after{
        height: 2px;
        background-color: #fff !important;
        width: 80% !important;
        position: relative;
        left: 30px;
        opacity: 0.4;
        top: 10px;
      }
      
      &-content{
        position: relative;
        right: 70px;
        width: 280px !important;
        display: flex !important;
        align-items: center;
        flex-direction: column;
        
        .ant-steps-item-title, .ant-steps-item-description{
          color: #fff !important;
        }

        .ant-steps-item-title{
          font-size: 20px !important;
          font-weight: 500 !important;
          margin-bottom: 12px;
        }

        .ant-steps-item-description{
          width: 89%;
          white-space: pre;
          text-align: center;
          font-size: 16px;
          font-weight: 300 !important;
        }
      }
    }
  }
  
  .adaptiveContainer{
    padding-top: 60px;
    
    &:before{
      position: absolute;
      top: 24px;
      content: "";
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      border-top: 2px solid #FFFFFF;
      opacity: 0.4;
      width: 40%;
      
      @media (max-width: 1200px) {
        top: 86px;
      }
      
      @media (max-width: 400px){
        width: 30%;
      }
    }

    .adaptive-icon{
      
    }
    .adaptive-title{
      margin-top: 12px;
      margin-bottom: 12px;
      color: #fff;
      font-size: 18px;
      
      @media (max-width: 590px){
        text-align: center;
      }
      
      @media (max-width: 450px){
        font-weight: 500;
        line-height: 24px;
      }
    }
    .adaptive-description{
      font-size: 16px;
      color: #fff;
      text-align: center;
      
      @media (max-width: 450px){
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  
  .adaptiveItemContainer{
    width: 300px;
    
    
  }
  
  @media (min-width: 1572px) and (max-width: 1640px) {
    .ant-steps{
      left: 30px !important;
    }
  }

  @media (min-width: 1458px) and (max-width: 1571px) {
    .ant-steps{
      left: 20px !important;
    }
  }

  @media (min-width: 1382px) and  (max-width: 1457px) {
    .ant-steps{
      left: 0 !important;
    }
  }

  @media (min-width: 1326px) and (max-width: 1381px) {
    .ant-steps{
      left: -30px !important;
    }
  }

  @media (min-width: 1271px) and (max-width: 1325px) {
    .ant-steps{
      left: -60px !important;
    }
  }

  @media (min-width: 1201px) and (max-width: 1270px) {
    .ant-steps{
      left: -120px !important;
    }
  }

  @media (max-width: 1200px) {
    .ant-steps{
      margin-left: 20px;
      margin-right: 20px;
      left: 0;
      &-item{
        .ant-steps-item-tail:after{
          height: 2px;
          background-color: #fff !important;
          width: 80% !important;
          position: relative;
          left: 30px;
          top: 10px;
        }

        &-content{
          position: relative;
          right: 110px;
          width: 100% !important;
          display: flex !important;
          align-items: center;
          flex-direction: column;

          .ant-steps-item-title, .ant-steps-item-description{
            color: #fff !important;
          }

          .ant-steps-item-title{
            font-size: 18px !important;
            font-weight: 500 !important;
          }

          .ant-steps-item-description{
            width: 89%;
            white-space: pre;
            text-align: center;
            font-size: 14px;
            font-weight: 300 !important;
          }
        }
      }
    }
  }
  
  
`