import React from "react";
import {StyledMainContainer} from "../Main/index.styles";
import {Section6} from "../../components";

export const Brands = () => {

    return(
        <StyledMainContainer>
            <Section6 />
        </StyledMainContainer>
    )
}