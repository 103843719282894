import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ROUTES_CONFIG} from "./config/routes.config";
import {ConfigProvider} from "antd";
import {LightTheme} from "./assets";

function App() {
  const router = createBrowserRouter(ROUTES_CONFIG)
  
  return (
    <ConfigProvider theme={LightTheme}>
      <RouterProvider router={router}/>
    </ConfigProvider>
  );
}

export default App;
