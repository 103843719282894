import styled from "styled-components";

export const StyledAreaSection2 = styled.div`
  
  background: #fff !important;
  background-color: #fff !important;
  
  padding-bottom: 60px;
  padding-top: 40px;
  
  .h1-text-adaptive{
    display: none;
  }

  .ant-btn:not(.backButton) {
    margin-top: 42px;
    background-color: #fff;
    border: 1px solid rgba(0, 51, 153, 1);
    transition: 200ms;

    span{
      transition: 200ms;
      color: rgba(0, 51, 153, 1);
    }

    &:hover{
      transition: 200ms;
      background-color: rgba(0, 51, 153, 1) !important;
      border-color: rgba(0, 51, 153, 1) !important;

      span{
        transition: 200ms;
        color: #fff;
      }
    }
  }

  h1{
    font-size: 40px;
    
    @media (max-width: 834px){
      font-size: 32px !important;
    }

    @media (max-width: 430px){
      font-size: 28px !important;
    }

    @media (max-width: 385px){
      font-size: 24px !important;
    }
  }
  
    .areaName{
      text-transform: uppercase;
      line-height: 28px;
      font-size: 18px;
    }
  
    .areaCode{
      color: rgba(0, 51, 153, 1);
      line-height: 26px;
      padding-right: 8px;
      font-size: 18px;
      
      @media (max-width: 330px) {
        padding-right: 2px;
      }
    }
  
    @media (max-width: 1331px){
        .areaName {
            font-size: 16px;
        }
    }

    @media (max-width: 1065px){
        .h1-text{
            font-size: 32px;
        }
        
        .areaName {
            font-size: 14px;
        }
    }
    
  @media(max-width: 730px){
    
    .h1-text{
        font-size: 24px !important;
        margin-top: 56px !important;
        margin-bottom: 32px !important;
    }
  }
  
  @media (max-width: 503px) {
    .areaName{
      line-height: 20px;
      font-size: 12px;
    }
    .areaCode{
      line-height: 20px;
      font-size: 12px;
    }

      .h1-text{
        margin-top: 20px !important;
        margin-bottom: 40px !important;
      }
  }

  @media (max-width: 439px) {
    .areaName{
      line-height: 20px;
      font-size: 10px;
    }
    .areaCode{
      line-height: 20px;
      font-size: 10px;
    }
    
    .backButton{
      width: 40px;
      height: 40px;
      
      svg{
        position: relative;
        right: 4px;
      }
    }
  }

  @media (max-width: 350px) {
    .backButton{
      width: 36px;
      height: 36px;

      svg{
        position: relative;
        right: 6px;
      }
    }
  }
  

  @media (max-width: 322px){
    .areaName{
      font-size: 10px;
    }
    .areaCode{
      font-size: 9px;
    }
  }
`