import React from "react";
import {Col, Flex, Row, Grid} from "antd";
import {StyledSection4Container} from "./Section4.styles";
import {
    RefrigeratorApplianceIcon,
    DishwasherIcon,
    WasherApplianceIcon,
    DryerIcon,
    OvenIcon, FreezerIcon, RangeHoodIcon, MicrowaveIcon, GasApplienceIcon, StoveIcon, BackgroundIcon
} from "../../../../assets";

const {useBreakpoint} = Grid;

export const Section4 = () => {

    const screens = useBreakpoint();

    const ourService = [
        {
            image: <RefrigeratorApplianceIcon/>,
            title: "Refrigerator",

        },
        {
            image: <DishwasherIcon/>,
            title: "Dishwasher",

        },
        {
            image: <WasherApplianceIcon/>,
            title: "Washer",
        },
        {
            image: <DryerIcon/>,
            title: "Dryer",
        },
        {
            image: <OvenIcon/>,
            title: "Oven",
        },
        {
            image: <FreezerIcon/>,
            title: "Freezer",

        },
        {
            image: <RangeHoodIcon/>,
            title: "Range Hood",

        },
        {
            image: <MicrowaveIcon/>,
            title: "Microwave",
        },
        {
            image: <GasApplienceIcon/>,
            title: "Gas Appliance",
        },
        {
            image: <StoveIcon/>,
            title: "Stove",
        },
    ]

    return (
        <StyledSection4Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{flexDirection: "column", marginTop: 80, marginBottom: 100}} align="middle">
                    <h1 className="title">Our services</h1>
                    <span className="subtitle">Breakdowns of home appliances might make you а malfunctioning appliance can significantly impact your daily life, causing inconvenience and disruptions. Fortunately, you have reliable helpers!</span>
                </Row>

                {!screens.md ? (
                  <Row justify="space-between">
                      {ourService.map((item,index) => (
                        <Col key={index + "-section4-item"} span={10} className="ourServiceContainer">
                            <Row style={{flexDirection: "column"}} align="middle">
                                <Row style={{flexDirection: "column", zIndex: 12}} align="middle">
                                    {item.image}
                                    <h3 className="ourServiceTitle">{item.title} <br/> Repair</h3>
                                </Row>
                            </Row>
                        </Col>
                        ))
                      }
                  </Row>
                ) : (
                    <Flex justify="space-between" wrap="wrap" style={{marginLeft: 37, marginRight: 24}} gap={!screens.md ? 65 : 50}>
                        {ourService.map((item,index) => (
                            <Col key={index + "-section4-item"} xs={7} sm={4} className="ourServiceContainer">
                                <Row style={{flexDirection: "column"}} align="middle">
                                    <Row style={{flexDirection: "column", zIndex: 12}} align="middle">
                                        {item.image}
                                        <h3 className="ourServiceTitle">{item.title} <br/> Repair</h3>
                                    </Row>
                                </Row>
                            </Col>
                        ))}
                    </Flex>
                )}
            </Col>
        </StyledSection4Container>
    )
}