import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.0487 3H4.95131C4.43572 3 4 3.43572 4 3.95131V46.0487C4 46.5643 4.43572 47 4.95131 47H47.0487C47.5643 47 48 46.5643 48 46.0487V3.95131C47.9274 3.43572 47.4844 3 47.0487 3ZM46.0974 45.0974H5.83001V4.83001H46.0974V45.0974Z" fill="#000000"/>
    <path d="M14.98 20.4286C18.4948 20.4286 21.4214 17.5747 21.4214 13.9873C21.4214 10.3999 18.5674 7.54596 14.98 7.54596C11.3926 7.54596 8.5387 10.3999 8.5387 13.9873C8.5387 17.5747 11.4653 20.4286 14.98 20.4286ZM14.98 9.44859C17.4709 9.44859 19.5914 11.4965 19.5914 14.0599C19.5914 16.6234 17.5435 18.6712 14.98 18.6712C12.4166 18.6712 10.3687 16.6234 10.3687 14.0599C10.3687 11.4965 12.4892 9.44859 14.98 9.44859Z" fill="#000000"/>
    <path d="M36.9473 20.4286C40.4621 20.4286 43.3887 17.5747 43.3887 13.9873C43.3887 10.3999 40.5347 7.54596 36.9473 7.54596C33.36 7.54596 30.506 10.3999 30.506 13.9873C30.506 17.5747 33.4326 20.4286 36.9473 20.4286ZM36.9473 9.44859C39.4382 9.44859 41.5587 11.4965 41.5587 14.0599C41.5587 16.6234 39.5108 18.6712 36.9473 18.6712C34.3839 18.6712 32.336 16.6234 32.336 14.0599C32.336 11.4965 34.4565 9.44859 36.9473 9.44859Z" fill="#000000"/>
    <path d="M14.98 42.3887C18.4948 42.3887 21.4214 39.5347 21.4214 35.9473C21.4214 32.36 18.5674 29.506 14.98 29.506C11.3926 29.506 8.5387 32.36 8.5387 35.9473C8.5387 39.5347 11.4653 42.3887 14.98 42.3887ZM14.98 31.4086C17.4709 31.4086 19.5914 33.4565 19.5914 36.02C19.5914 38.5834 17.5435 40.6313 14.98 40.6313C12.4166 40.6313 10.3687 38.5834 10.3687 36.02C10.3687 33.4565 12.4892 31.4086 14.98 31.4086Z" fill="#000000"/>
    <path d="M36.9473 42.3887C40.4621 42.3887 43.3887 39.5347 43.3887 35.9473C43.3887 32.36 40.5347 29.506 36.9473 29.506C33.36 29.506 30.506 32.36 30.506 35.9473C30.506 39.5347 33.4326 42.3887 36.9473 42.3887ZM36.9473 31.4086C39.4382 31.4086 41.5587 33.4565 41.5587 36.02C41.5587 38.5834 39.5108 40.6313 36.9473 40.6313C34.3839 40.6313 32.336 38.5834 32.336 36.02C32.336 33.4565 34.4565 31.4086 36.9473 31.4086Z" fill="#000000"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
