import React from "react";
import {StyledSection3Container} from "./Section3.styles";
import {Col, Row} from "antd";
import {MyCard} from "../../../common/MyCard/MyCard";
import {MyButton} from "../../../common/MyButton/MyButton";

export const Section3 = ({setIsModalOpen}) => {

    return (
        <StyledSection3Container>
            <Col xs={{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row justify="end">
                    <MyCard className="advantagesCard">
                        <h1 className="advantagesTitle">Appliance repair <br/> service near me</h1>
                        <span className="advantagesText">We repair a wide range of home appliances and make sure they work smoothly after installation, maintenance or repair. All our specialists are well trained and certified to repair home appliances.</span>
                        <Col>
                            <MyButton style={{marginTop: 30}} type="primary" onClick={() => setIsModalOpen(true)}>Schedule
                                Appointment</MyButton>
                        </Col>
                    </MyCard>
                </Row>
            </Col>
        </StyledSection3Container>
    )
}