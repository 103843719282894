import styled from 'styled-components'
import {Carousel} from "antd";

import quoteSvg from '../../../../assets/icons/Main/section9/quote.svg'

export const StyledSection9Container = styled.div`
  margin-top: 40px;
  padding-bottom: 100px;  
  position: relative;

  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;

    .title{
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;

      @media (max-width: 450px) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .subtitle{
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      
      @media (max-width: 450px){
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
`

export const StyledCarousel = styled(Carousel)`

  .ant-image-img{
    width: 48px;
    height: 48px;
    border-radius: 40px;
    
    @media (max-width: 1568px){
      width: 40px;
      height: 40px;
    }

    @media (max-width: 1450px){
      width: 32px;
      height: 32px;
    }
    
    @media (max-width: 991px){
      width: 32px;
      height: 32px;
    }
  }
  
  .slick-list {
    z-index: 2;
    padding-top: 30px;
    padding-bottom: 30px;

    .slick-track {

      .slick-slide {
        background-color: #ffffff !important;
        z-index: 3;

        & > div {

          .item {
            padding: 35px;
            height: 258px;
            border-radius: 12px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);

            &:before {
              content: '';
              width: 73px;
              height: 45px;
              background-image: url(${quoteSvg});
              background-repeat: no-repeat;
              position: absolute;
              left: 30px;
              top: -5px;
              z-index: 0;
              
              @media (max-width: 991px){
                top: -10px;
                width: 60px;
                height: 35px;
              }

              @media (max-width: 400px){
                top: -12px;
                left: 20px;
                width: 60px;
                height: 35px;
              }
            }

            .userInfo {
              position: absolute;
              bottom: 20px;
              
              span{
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                margin-left: 20px;
                
                @media (max-width: 991px){
                  font-size: 16px;
                  margin-left: 14px;
                }
              }
            }

            &:first-child {
              margin-right: 15px;
            }

            span {
              color: {
              }
            }

            .item_text {
              z-index: 1;
              position: relative;
              display: block;

              @media (max-width: 991px){
                margin-top: 12px;
                font-size: 13px !important;
                line-height: 20px !important;
              }

              @media (max-width: 450px){
                margin-top: 8px;
                font-size: 14px !important;
                line-height: 22px !important;
              }

              @media (max-width: 400px){
                margin-top: 8px;
                font-size: 13px !important;
                line-height: 20px !important;
              }

              @media (max-width: 359px){
                margin-top: 8px;
                font-size: 12px !important;
                line-height: 18px !important;
              }
            }
          }
        }


      }
    }
  }

  .slick-dots {
    bottom: -20px;

    li {
      button {
        border-radius: 50px;
        height: 8px !important;
        width: 8px !important;
        background: rgb(182, 182, 187) !important;
      }

      &.slick-active {
        width: 16px;
        button {
          width: 9px !important;
          height: 9px !important;
          border: 2px solid #003399;
          background: #fff !important;
          
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 375px) {
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            margin: 12px;

            .item {
              padding: 16px;

              span {
                font-size: 14px;
                line-height: 18px;
              }

              .item_text {
                margin-bottom: 12px;
              }

              .item_title {
                padding-top: 6px;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 375px) and (max-width: 768px) {
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            margin: 12px;

            .item {
              padding: 16px;

              span {
                font-size: 18px;
                line-height: 24px;
              }

              .item_text {
                margin-bottom: 12px;
              }

              .item_title {
                padding-top: 6px;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            margin: 12px;

            .item {
              padding: 20px;

              span {
                font-size: 18px;
                line-height: 24px;
              }

              .item_text {
                margin-bottom: 20px;
              }

              .item_title {
                padding-top: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .slick-list {
      .slick-track {
        .slick-slide {
          & > div {
            margin: 12px;

            .item {
              padding: 32px;

              span {
                font-size: 18px;
                line-height: 24px;
              }

              .item_text {
                margin-bottom: 24px;
              }

              .item_title {
                padding-top: 12px;
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
  
  @media (min-width: 768px) and(max-width: 1683px){
    .slick-list {
      .slick-track {
        .slick-slide {

          & > div {
            .item {

              span {
                font-size: 15px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
    
  }

  @media(max-width: 1450px){
    .slick-list {
      .slick-track {
        .slick-slide {

          & > div {
            .item {

              span {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

  }

  @media(max-width: 1254px){
    .slick-list {
      .slick-track {
        .slick-slide {

          & > div {
            .item {

              span {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }

  }
  
  @media (min-width: 1200px) {
    .slick-list {
      .slick-track {
        .slick-slide {
          
          & > div {
              margin-right: 30px;
            margin-left: 30px;
          }
        }
      }
    }
  }

`