import Icon from "@ant-design/icons";


const Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="176" height="176" viewBox="0 0 176 176" fill="none">
          <circle cx="88" cy="88" r="83" stroke="white" strokeWidth="10"/>
          <circle cx="88" cy="88" r="80" fill="#D7B56D"/>
          <path
            d="M73.101 81.3038L94.6861 102.9C93.076 108.948 94.4647 113.614 97.9062 117.055C101.376 120.527 106.306 121.789 110.957 120.514C111.621 120.333 112.139 119.811 112.315 119.143C112.491 118.478 112.298 117.768 111.812 117.282L106.617 112.089C105.097 110.567 105.113 108.129 106.613 106.624C108.121 105.124 110.618 105.149 112.088 106.622L117.287 111.817C117.775 112.305 118.485 112.496 119.151 112.318C119.817 112.14 120.337 111.622 120.519 110.958C121.78 106.352 120.539 101.395 117.058 97.9135C113.662 94.5151 108.949 93.076 102.895 94.6986L81.312 73.102L81.5977 72.0241C82.8407 67.3625 81.4974 62.351 78.0938 58.947C74.6278 55.4806 69.6993 54.2078 65.0395 55.4862C64.3754 55.6678 63.857 56.1897 63.6811 56.8573C63.5051 57.523 63.6981 58.2321 64.1843 58.7182L69.3815 63.9131C70.9007 65.4354 70.8855 67.8731 69.3852 69.3784C67.8963 70.8705 65.3933 70.8686 63.91 69.3822L58.7128 64.1854C58.2247 63.6975 57.5153 63.5065 56.8493 63.6842C56.1833 63.862 55.663 64.3802 55.4814 65.044C54.2195 69.6507 55.4606 74.6073 58.9437 78.0889C62.3397 81.4853 67.0468 82.9302 73.1029 81.3038H73.101Z"
            fill="black"/>
          <path
            d="M75.9349 89L57.2885 107.647C54.2372 110.698 54.2372 115.661 57.2885 118.712C60.3398 121.763 65.3022 121.763 68.3535 118.712L87 100.064L75.9349 89ZM66.5151 112.642C65.7518 113.405 64.5141 113.405 63.7488 112.642C62.9855 111.879 62.9855 110.641 63.7488 109.876L75.6136 98.0099C76.3769 97.2466 77.6146 97.2466 78.3798 98.0099C79.1431 98.7732 79.1431 100.011 78.3798 100.776L66.5151 112.642Z"
            fill="black"/>
          <path
            d="M97.4525 84L110.43 70.902L111.576 72.0638C112.462 72.9618 113.948 72.7765 114.592 71.6873L120.726 61.3274C121.18 60.5593 121.061 59.5753 120.435 58.9409L117.111 55.5724C116.485 54.938 115.514 54.8176 114.756 55.2781L104.534 61.4937C103.457 62.1471 103.276 63.6509 104.163 64.5508L104.977 65.3743L92 78.4723L97.4544 84H97.4525Z"
            fill="black"/>
          <path
            d="M29.36 96.0701C29.519 95.5796 29.5694 95.0112 29.5151 94.3669C29.4589 93.7032 29.3077 93.1465 29.0615 92.6968C28.8153 92.2472 28.4993 91.9124 28.1115 91.6964C27.7238 91.4803 27.2876 91.3927 26.8049 91.4336C26.4481 91.4647 26.1418 91.5582 25.8879 91.7178C25.6339 91.8754 25.4226 92.0779 25.2539 92.3251C25.0852 92.5723 24.9515 92.8448 24.8526 93.1465C24.7537 93.4462 24.682 93.7577 24.6355 94.0769L24.5095 94.871C24.4843 95.0443 24.4494 95.2175 24.4048 95.3908C24.3602 95.562 24.3001 95.7217 24.2245 95.8676C24.1489 96.0136 24.0519 96.1304 23.9317 96.2219C23.8115 96.3114 23.6661 96.364 23.4955 96.3796C23.2881 96.3951 23.1 96.3504 22.9275 96.2453C22.755 96.1401 22.6134 95.9805 22.501 95.7645C22.3885 95.5484 22.3188 95.2837 22.2916 94.9723C22.2528 94.5265 22.3207 94.1567 22.4932 93.8608C22.6658 93.565 22.9256 93.382 23.2745 93.3139L23.1504 91.8696C22.6871 91.9183 22.2858 92.0837 21.9485 92.3679C21.6111 92.6521 21.361 93.0258 21.1963 93.4871C21.0315 93.9484 20.9753 94.472 21.0257 95.0618C21.0761 95.6418 21.2215 96.1518 21.4638 96.5937C21.7061 97.0355 22.0202 97.3723 22.4099 97.6058C22.7976 97.8394 23.2357 97.9367 23.7224 97.8939C24.3137 97.8433 24.7692 97.6039 25.093 97.1796C25.4148 96.7533 25.6339 96.1927 25.7502 95.4939L25.9111 94.5304C25.9615 94.2268 26.0236 93.9601 26.0992 93.7285C26.1748 93.4968 26.2795 93.3139 26.4094 93.1776C26.5393 93.0414 26.7118 92.9635 26.927 92.9421C27.1635 92.9246 27.3768 92.9791 27.5648 93.1036C27.7529 93.2282 27.906 93.4112 28.0262 93.6487C28.1445 93.8861 28.2181 94.1684 28.2472 94.4954C28.2744 94.8088 28.2511 95.0949 28.1794 95.3557C28.1076 95.6165 27.9816 95.8326 27.8052 96.0058C27.6288 96.1791 27.3961 96.2881 27.1112 96.3348L27.2372 97.8122C27.7839 97.744 28.2356 97.5591 28.5943 97.2613C28.9529 96.9635 29.2108 96.5684 29.3697 96.0779L29.36 96.0701Z"
            fill="black"/>
          <path
            d="M29.1676 87.3716L27.1805 86.5385L27.4054 83.4203L29.4914 82.8811L29.6058 81.2772L21.1338 83.6013L21 85.4563L29.0513 88.9755L29.1657 87.3716H29.1676ZM22.7642 84.6193L26.1724 83.7376L26.0057 86.0461L22.7584 84.6835L22.7623 84.6193H22.7642Z"
            fill="black"/>
          <path
            d="M30.4298 76.9022L24.9201 75.4229L24.9394 75.3489L31.0172 74.5995L31.2944 73.5562L26.4109 69.8677L26.4322 69.7917L31.9284 71.2672L32.3006 69.8716L24.2977 67.7227L23.8247 69.5037L28.9874 73.4453L28.9622 73.5406L22.5297 74.3698L22.0547 76.1509L30.0575 78.2978L30.4298 76.9022Z"
            fill="black"/>
          <path
            d="M35.8977 61.7869L34.7577 61.2536L33.0982 64.8175L31.051 63.8579L32.5767 60.5742L31.4368 60.0409L29.911 63.3227L27.8735 62.3669L29.5175 58.8322L28.3775 58.2969L26.0977 63.1981L33.6042 66.7173L35.8977 61.7869Z"
            fill="black"/>
          <path
            d="M40.8943 53.9569C41.4041 53.2854 41.7085 52.608 41.8074 51.9267C41.9062 51.2455 41.8074 50.5895 41.5069 49.9569C41.2064 49.3243 40.714 48.7462 40.0277 48.2207C39.3433 47.6971 38.659 47.3759 37.9746 47.2533C37.2903 47.1326 36.6389 47.2066 36.0165 47.4791C35.3942 47.7516 34.8359 48.2148 34.3377 48.8727L32.6025 51.1598L39.1921 56.1992L40.8923 53.9569H40.8943ZM34.5451 50.7511L35.3186 49.7292C35.6579 49.2815 36.0262 48.9701 36.4217 48.7949C36.8172 48.6197 37.2418 48.5827 37.6916 48.682C38.1433 48.7812 38.6202 49.0246 39.1242 49.41C39.6283 49.7954 39.9889 50.1944 40.208 50.6051C40.427 51.0158 40.5026 51.4421 40.4367 51.8781C40.3708 52.316 40.1634 52.7637 39.8144 53.225L39.0661 54.2099L34.5412 50.7511H34.5451Z"
            fill="black"/>
          <path
            d="M45.8553 48.0754L44.948 46.1134L47.2085 43.9625L49.1123 44.9766L50.2755 43.871L42.4587 39.8516L41.1133 41.1304L44.694 49.181L45.8572 48.0754H45.8553ZM42.9763 41.7065L46.086 43.3649L44.4129 44.9571L42.9298 41.7533L42.9763 41.7085V41.7065Z"
            fill="black"/>
          <path
            d="M52.6386 31.9824L51.2466 32.9557L51.6789 37.1367L51.613 37.1834L47.8559 35.3284L46.4658 36.3016L51.9077 38.8496L53.6738 41.3955L54.901 40.5372L53.1349 37.9912L52.6386 31.9824Z"
            fill="black"/>
          <path
            d="M63.7553 35.5125L63.6196 33.3539L66.5044 32.1743L67.9099 33.8133L69.393 33.206L63.5634 26.6113L61.8477 27.314L62.2722 36.1198L63.7553 35.5125ZM63.3753 28.5286L65.6727 31.2069L63.5382 32.0809L63.3152 28.5539L63.3753 28.5305V28.5286Z"
            fill="black"/>
          <path
            d="M73.4577 32.1253L72.8295 29.3866L74.3747 29.0304C74.997 28.8864 75.4952 28.6489 75.8674 28.32C76.2397 27.991 76.4898 27.5998 76.6158 27.1443C76.7418 26.6908 76.7437 26.2042 76.6255 25.6845C76.5072 25.1706 76.294 24.7346 75.9838 24.3803C75.6736 24.0261 75.282 23.7808 74.807 23.6465C74.332 23.5122 73.7853 23.5161 73.1649 23.6601L70.1367 24.3589L71.994 32.464L73.4557 32.1253H73.4577ZM73.2212 24.937C73.5856 24.8533 73.8997 24.8455 74.1595 24.9136C74.4193 24.9818 74.6306 25.1141 74.7934 25.3068C74.9543 25.4995 75.0687 25.7409 75.1346 26.029C75.2005 26.3171 75.2025 26.5837 75.1424 26.8309C75.0823 27.0781 74.9504 27.2922 74.7469 27.4713C74.5453 27.6523 74.2622 27.7827 73.8997 27.8664L72.5523 28.1779L71.8796 25.2465L73.2192 24.937H73.2212Z"
            fill="black"/>
          <path
            d="M82.1571 30.5946L81.9477 27.7917L83.5297 27.673C84.1675 27.6243 84.6948 27.4666 85.1136 27.198C85.5323 26.9294 85.8367 26.5791 86.0286 26.1489C86.2206 25.7187 86.2962 25.2379 86.2574 24.7066C86.2186 24.181 86.0713 23.7177 85.8193 23.3207C85.5672 22.9236 85.2163 22.6219 84.7666 22.4156C84.3168 22.2092 83.7759 22.1314 83.14 22.18L80.042 22.4136L80.6604 30.7075L82.1571 30.5946ZM83.0043 23.453C83.3785 23.4258 83.6886 23.4647 83.9349 23.5718C84.183 23.6788 84.3711 23.8404 84.5029 24.0564C84.6347 24.2725 84.7103 24.5275 84.7317 24.8214C84.753 25.1153 84.7161 25.38 84.6192 25.6156C84.5223 25.8511 84.3594 26.0418 84.1326 26.1898C83.9058 26.3377 83.6072 26.4253 83.235 26.4525L81.8566 26.5557L81.6317 23.5542L83.0024 23.4511L83.0043 23.453Z"
            fill="black"/>
          <path
            d="M94.6085 29.383L90.9677 29.1377L91.4349 22.1012L89.9383 22L89.3857 30.2978L94.5232 30.6423L94.6085 29.383Z"
            fill="black"/>
          <path d="M98.1385 22.8009L96.7812 31.0057L98.2615 31.2526L99.6188 23.0477L98.1385 22.8009Z" fill="black"/>
          <path
            d="M103.252 30.2378L106.245 31.102L106.34 33.2626L107.88 33.7084L107.364 24.9045L105.584 24.3906L100.491 31.5731L102.031 32.0188L103.254 30.2378H103.252ZM105.972 26.2787L106.034 26.2962L106.189 29.8271L103.973 29.1867L105.972 26.2768V26.2787Z"
            fill="black"/>
          <path
            d="M114.284 30.3001L114.346 30.3313L115.405 37.1361L116.607 37.7201L120.211 30.232L118.867 29.5799L116.411 34.6836L116.345 34.6524L115.292 27.8437L114.083 27.2578L110.479 34.7459L111.83 35.4018L114.284 30.3001Z"
            fill="black"/>
          <path
            d="M122.348 41.6404C122.749 41.9305 123.157 42.1329 123.57 42.2497C123.984 42.3665 124.39 42.4074 124.789 42.3704C125.188 42.3334 125.564 42.2283 125.917 42.057C126.27 41.8857 126.586 41.656 126.861 41.3679L125.638 40.4745C125.46 40.6458 125.266 40.7723 125.058 40.856C124.851 40.9397 124.638 40.9825 124.423 40.9864C124.205 40.9903 123.99 40.9553 123.775 40.8813C123.56 40.8074 123.352 40.6984 123.151 40.5524C122.788 40.2896 122.528 39.9684 122.377 39.585C122.224 39.2035 122.193 38.7772 122.286 38.3081C122.377 37.839 122.61 37.3465 122.98 36.8307C123.345 36.3227 123.732 35.9509 124.145 35.7134C124.558 35.476 124.971 35.367 125.384 35.3884C125.797 35.4079 126.187 35.5499 126.553 35.8147C126.759 35.9626 126.931 36.13 127.069 36.3149C127.207 36.4998 127.307 36.6984 127.367 36.9086C127.429 37.1188 127.451 37.3387 127.431 37.5626C127.412 37.7884 127.348 38.0142 127.239 38.2419L128.467 39.1295C128.686 38.7168 128.819 38.3061 128.866 37.8954C128.913 37.4847 128.883 37.0857 128.779 36.7003C128.672 36.3149 128.496 35.9529 128.25 35.6161C128.003 35.2774 127.693 34.9738 127.319 34.7032C126.732 34.2789 126.109 34.0375 125.448 33.9772C124.787 33.9169 124.138 34.0473 123.496 34.3704C122.856 34.6935 122.275 35.219 121.753 35.9451C121.233 36.6711 120.921 37.3894 120.817 38.0998C120.712 38.8122 120.793 39.4721 121.061 40.0813C121.328 40.6906 121.757 41.2103 122.348 41.6366V41.6404Z"
            fill="black"/>
          <path
            d="M131.323 49.4413L132.209 48.5459L129.431 45.7703L131.026 44.1606L133.583 46.7163L134.471 45.8209L131.914 43.2652L133.5 41.6652L136.255 44.4175L137.143 43.5221L133.324 39.707L127.48 45.6029L131.323 49.4413Z"
            fill="black"/>
          <path
            d="M136.19 55.6517L138.722 53.9758L139.561 55.2566L137.925 58.297L138.846 59.7004L140.599 56.3856C140.647 56.4186 140.696 56.4498 140.744 56.479C141.161 56.7281 141.595 56.8391 142.043 56.8138C142.493 56.7865 142.939 56.6269 143.383 56.333C143.825 56.041 144.15 55.6926 144.36 55.2858C144.569 54.879 144.647 54.4332 144.594 53.9486C144.54 53.4639 144.338 52.9539 143.989 52.4206L142.28 49.8162L135.362 54.3924L136.188 55.6498L136.19 55.6517ZM142.817 52.9208C143.022 53.2342 143.146 53.5223 143.185 53.7812C143.224 54.0401 143.189 54.2756 143.078 54.4897C142.968 54.7019 142.789 54.8907 142.545 55.0522C142.299 55.2138 142.06 55.3033 141.832 55.3189C141.601 55.3345 141.376 55.2702 141.159 55.1281C140.942 54.986 140.731 54.7603 140.525 54.4469L139.763 53.2868L142.06 51.7666L142.817 52.9189V52.9208Z"
            fill="black"/>
          <path
            d="M142.335 66.8734L143.473 66.3362L141.81 62.7742L143.857 61.8107L145.389 65.0905L146.529 64.5532L144.997 61.2734L147.033 60.3158L148.683 63.8486L149.823 63.3133L147.535 58.416L140.034 61.945L142.335 66.8734Z"
            fill="black"/>
          <path
            d="M143.831 71.0943L146.512 70.2846L146.968 71.8087C147.152 72.4237 147.419 72.9065 147.77 73.2588C148.121 73.6111 148.528 73.8349 148.988 73.9303C149.447 74.0257 149.932 73.9984 150.44 73.8447C150.944 73.6928 151.361 73.4495 151.694 73.1167C152.026 72.7838 152.245 72.3751 152.347 71.8885C152.45 71.4038 152.409 70.8549 152.227 70.2437L151.333 67.2578L143.4 69.652L143.833 71.0943H143.831ZM150.959 70.3858C151.068 70.7459 151.095 71.0593 151.045 71.3259C150.992 71.5907 150.876 71.8125 150.696 71.9858C150.513 72.161 150.283 72.2914 150.002 72.3751C149.72 72.4607 149.455 72.4802 149.205 72.4354C148.955 72.3906 148.734 72.2739 148.542 72.0831C148.35 71.8924 148.201 71.6179 148.092 71.2598L147.695 69.9303L150.566 69.0641L150.961 70.3858H150.959Z"
            fill="black"/>
          <path
            d="M145.697 78.2701L147.82 78.6439L148.286 81.7348L146.368 82.7217L146.607 84.312L154.361 80.1699L154.084 78.3305L145.457 76.6779L145.695 78.2682L145.697 78.2701ZM152.547 79.5392L149.414 81.1529L149.069 78.8638L152.535 79.475L152.545 79.5392H152.547Z"
            fill="black"/>
          <path d="M154.916 86.3204L146.637 86.5918L146.686 88.0976L154.965 87.8262L154.916 86.3204Z" fill="black"/>
          <path
            d="M146.739 90.8202L146.626 92.3229L149.651 92.5525L149.536 94.0805L146.388 95.48L146.262 97.1559L149.674 95.6065C149.691 95.661 149.705 95.7194 149.726 95.772C149.904 96.2255 150.18 96.5798 150.55 96.8348C150.922 97.0898 151.372 97.2377 151.901 97.2766C152.429 97.3175 152.898 97.2396 153.313 97.0469C153.728 96.8542 154.059 96.5506 154.313 96.134C154.565 95.7175 154.716 95.19 154.765 94.5535L154.999 91.443L146.739 90.8163V90.8202ZM153.532 94.2304C153.505 94.6061 153.427 94.9078 153.299 95.1374C153.171 95.3671 153.001 95.5306 152.783 95.6319C152.566 95.7331 152.31 95.772 152.018 95.7486C151.725 95.7253 151.483 95.6494 151.291 95.5209C151.099 95.3905 150.961 95.2036 150.876 94.9584C150.79 94.7131 150.761 94.4036 150.789 94.0319L150.893 92.6479L153.636 92.8562L153.532 94.2323V94.2304Z"
            fill="black"/>
          <path
            d="M35.4529 109.136L33.4832 107.678L32.1998 108.17L34.0454 109.544L34.0628 109.591L27.6885 112.032L28.2236 113.441L35.9627 110.475L35.4529 109.136Z"
            fill="black"/>
          <path
            d="M38.8164 120.162C39.2429 119.623 39.4833 119.063 39.5375 118.481C39.5918 117.899 39.4542 117.317 39.1265 116.739C38.797 116.161 38.3705 115.746 37.8451 115.497C37.3197 115.248 36.7168 115.172 36.0382 115.265C35.3597 115.359 34.6288 115.629 33.8437 116.077C33.0585 116.526 32.4517 117.021 32.0213 117.562C31.5909 118.103 31.3466 118.666 31.2885 119.248C31.2303 119.832 31.366 120.414 31.6956 120.994C32.0252 121.574 32.4556 121.988 32.9829 122.235C33.5102 122.483 34.117 122.559 34.7994 122.461C35.4818 122.366 36.2166 122.091 37.0037 121.642C37.785 121.194 38.3899 120.7 38.8164 120.162ZM38.1844 118.306C38.1107 118.638 37.9129 118.975 37.5931 119.318C37.2732 119.66 36.8253 119.995 36.2554 120.322C35.3907 120.816 34.6618 121.06 34.0705 121.052C33.4792 121.044 33.0527 120.815 32.7968 120.363C32.6262 120.061 32.5777 119.746 32.6514 119.413C32.725 119.08 32.9228 118.743 33.2465 118.401C33.5703 118.058 34.0201 117.724 34.5939 117.398C35.4528 116.91 36.1778 116.669 36.7691 116.676C37.3604 116.684 37.785 116.912 38.0409 117.36C38.2115 117.659 38.258 117.973 38.1844 118.306Z"
            fill="black"/>
          <path
            d="M45.1488 126.29C45.1081 125.706 44.8793 125.155 44.4625 124.637C44.0457 124.119 43.5571 123.781 42.9968 123.621C42.4385 123.462 41.8317 123.483 41.1764 123.685C40.5231 123.888 39.8446 124.273 39.1408 124.842C38.439 125.412 37.9195 126 37.5821 126.603C37.2448 127.207 37.0936 127.8 37.1304 128.386C37.1672 128.972 37.3941 129.523 37.8148 130.043C38.2355 130.562 38.724 130.901 39.2862 131.061C39.8484 131.218 40.4572 131.195 41.1163 130.989C41.7735 130.782 42.456 130.393 43.1597 129.821C43.8596 129.252 44.3772 128.666 44.7106 128.065C45.0441 127.463 45.1914 126.872 45.1507 126.288L45.1488 126.29ZM43.3633 127.432C43.1015 127.822 42.7157 128.225 42.2039 128.641C41.4304 129.27 40.7499 129.628 40.1644 129.716C39.579 129.803 39.1214 129.645 38.7957 129.243C38.5786 128.974 38.4797 128.668 38.4991 128.33C38.5185 127.991 38.66 127.625 38.9237 127.234C39.1873 126.843 39.577 126.44 40.0927 126.025C40.8604 125.404 41.539 125.048 42.1244 124.958C42.7099 124.869 43.1655 125.025 43.4893 125.427C43.7045 125.694 43.8033 125.998 43.784 126.338C43.7646 126.679 43.625 127.043 43.3633 127.432Z"
            fill="black"/>
          <path
            d="M48.8159 130.685C48.5367 130.805 48.2944 130.988 48.0889 131.239L47.8097 131.576C47.6042 131.823 47.4666 132.094 47.3987 132.39C47.3289 132.684 47.3444 132.977 47.4394 133.269C47.5363 133.561 47.7302 133.83 48.023 134.073C48.3118 134.315 48.6065 134.455 48.9109 134.5C49.2152 134.544 49.5041 134.503 49.7794 134.383C50.0547 134.262 50.2951 134.075 50.5006 133.826L50.7798 133.489C50.9872 133.24 51.1248 132.968 51.1966 132.676C51.2683 132.384 51.2547 132.09 51.1597 131.796C51.0647 131.504 50.8709 131.235 50.5781 130.99C50.2931 130.751 49.9965 130.611 49.6922 130.568C49.3858 130.525 49.095 130.564 48.8159 130.685ZM49.9888 131.701C50.1943 131.872 50.2757 132.057 50.233 132.255C50.1904 132.454 50.1012 132.637 49.9636 132.804L49.6844 133.141C49.5468 133.306 49.3839 133.427 49.1939 133.501C49.0059 133.575 48.812 133.528 48.6123 133.361C48.4126 133.195 48.3312 133.011 48.3661 132.81C48.401 132.61 48.4882 132.425 48.6259 132.259L48.9051 131.922C49.0408 131.759 49.2075 131.638 49.4014 131.566C49.5952 131.492 49.791 131.537 49.9888 131.703V131.701Z"
            fill="black"/>
          <path
            d="M50.7801 137.35C50.4951 137.11 50.1984 136.97 49.8921 136.929C49.5858 136.889 49.295 136.929 49.0178 137.048C48.7406 137.169 48.5002 137.354 48.2947 137.601L48.0155 137.938C47.81 138.185 47.6724 138.455 47.6026 138.749C47.5328 139.043 47.5463 139.337 47.6413 139.633C47.7363 139.927 47.9302 140.196 48.221 140.439C48.5118 140.682 48.8084 140.822 49.1147 140.863C49.4191 140.904 49.7099 140.863 49.9871 140.741C50.2644 140.618 50.5048 140.435 50.7083 140.188L50.9875 139.851C51.1949 139.602 51.3326 139.329 51.4024 139.036C51.4722 138.742 51.4605 138.45 51.3655 138.156C51.2705 137.864 51.0767 137.595 50.782 137.348L50.7801 137.35ZM50.4369 138.619C50.3943 138.818 50.3051 139 50.1674 139.168L49.8883 139.505C49.7506 139.672 49.5878 139.791 49.3978 139.865C49.2097 139.939 49.0139 139.89 48.8142 139.723C48.6145 139.557 48.5331 139.372 48.568 139.168C48.6029 138.964 48.6882 138.781 48.8259 138.617L49.105 138.28C49.2427 138.113 49.4094 137.996 49.6052 137.924C49.801 137.852 49.9949 137.899 50.1907 138.062C50.3981 138.236 50.4815 138.422 50.4388 138.621L50.4369 138.619Z"
            fill="black"/>
          <path d="M44.1367 136.729L44.9141 137.379L54.5901 134.644L53.8127 133.994L44.1367 136.729Z" fill="black"/>
          <path
            d="M66.8133 142.162C66.633 141.81 66.3888 141.491 66.0824 141.205C65.7761 140.919 65.4136 140.681 64.9968 140.492C64.5024 140.268 64.0061 140.15 63.5059 140.136C63.0058 140.122 62.525 140.214 62.0616 140.409C61.5983 140.603 61.1718 140.903 60.7821 141.306C60.3924 141.709 60.0609 142.217 59.7856 142.828C59.427 143.628 59.2622 144.385 59.2951 145.098C59.3281 145.81 59.5355 146.445 59.9194 146.997C60.3032 147.55 60.8422 147.984 61.5382 148.298C62.1624 148.58 62.7731 148.703 63.3702 148.666C63.9673 148.629 64.5082 148.436 64.9968 148.085C65.4853 147.737 65.875 147.237 66.1658 146.589L66.5749 145.678L63.5118 144.29L63.029 145.362L64.7661 146.149C64.6032 146.493 64.3919 146.764 64.1302 146.958C63.8549 147.163 63.5428 147.274 63.1958 147.295C62.8487 147.317 62.4823 147.239 62.0985 147.066C61.6758 146.875 61.3501 146.602 61.1233 146.252C60.8965 145.902 60.7821 145.487 60.7821 145.008C60.7821 144.529 60.9101 144.004 61.166 143.431C61.4219 142.863 61.7282 142.423 62.0868 142.11C62.4455 141.794 62.8293 141.608 63.2384 141.547C63.6475 141.487 64.0546 141.547 64.4598 141.73C64.6866 141.833 64.8824 141.956 65.0472 142.1C65.212 142.246 65.3438 142.408 65.4427 142.589C65.5396 142.77 65.6075 142.964 65.6424 143.171C65.6773 143.379 65.6811 143.595 65.654 143.823L67.0479 144.453C67.1584 144.056 67.1933 143.661 67.1487 143.27C67.1061 142.879 66.9936 142.507 66.8133 142.155V142.162Z"
            fill="black"/>
          <path
            d="M75.5941 149.629C75.5146 149.965 75.3692 150.248 75.1598 150.477C74.9505 150.707 74.6926 150.861 74.3863 150.946C74.08 151.03 73.7407 151.028 73.3705 150.939C73.0002 150.849 72.6977 150.699 72.4631 150.485C72.2286 150.271 72.0677 150.016 71.9824 149.716C71.8971 149.416 71.8932 149.099 71.9746 148.762L73.1979 143.6L71.7381 143.252L70.4838 148.537C70.3461 149.117 70.3616 149.658 70.5264 150.162C70.6931 150.666 70.9917 151.1 71.424 151.464C71.8563 151.828 72.4011 152.089 73.0603 152.245C73.7155 152.402 74.3185 152.416 74.8671 152.286C75.4158 152.155 75.8791 151.904 76.2533 151.53C76.6294 151.157 76.8853 150.678 77.0229 150.1L78.2772 144.815L76.8174 144.467L75.5922 149.631L75.5941 149.629Z"
            fill="black"/>
          <path
            d="M84.49 145.469L81.1846 153.636L82.784 153.712L83.5653 151.695L86.6749 151.843L87.2643 153.924L88.8637 154L86.3395 145.554L84.49 145.467V145.469ZM84.0267 150.508L85.3023 147.215L85.3663 147.218L86.3279 150.617L84.0247 150.508H84.0267Z"
            fill="black"/>
          <path
            d="M97.7691 149.774C98.1743 149.506 98.4632 149.165 98.6357 148.746C98.8082 148.33 98.8606 147.857 98.7908 147.327C98.721 146.802 98.5523 146.354 98.279 145.988C98.0076 145.622 97.6412 145.356 97.1836 145.192C96.7261 145.029 96.1813 144.988 95.5513 145.072L92.4727 145.476L93.5486 153.722L95.0356 153.525L94.642 150.506L96.1561 150.308L98.1646 153.114L99.8241 152.896L97.6179 149.86C97.6683 149.831 97.7226 149.805 97.7691 149.774ZM94.1225 146.529L95.4854 146.35C95.8576 146.302 96.1678 146.315 96.4159 146.393C96.666 146.471 96.8618 146.605 97.0033 146.8C97.1449 146.993 97.236 147.236 97.2748 147.528C97.3135 147.82 97.2883 148.075 97.2011 148.289C97.1138 148.503 96.9588 148.678 96.7377 148.813C96.5167 148.947 96.2201 149.038 95.8518 149.087L94.4811 149.268L94.1244 146.529H94.1225Z"
            fill="black"/>
          <path
            d="M104.07 143.209L103.811 152.022L105.336 151.534L105.367 149.397L108.349 148.441L109.607 150.164L111.133 149.675L105.833 142.644L104.068 143.211L104.07 143.209ZM105.336 148.12L105.389 144.56L105.451 144.54L107.548 147.411L105.336 148.12Z"
            fill="black"/>
          <path
            d="M116.504 137.798L119.23 142.764L119.166 142.799L113.026 139.723L111.85 140.375L115.849 147.657L117.164 146.931L114.44 141.97L114.5 141.936L120.642 145.006L121.811 144.358L117.811 137.076L116.504 137.798Z"
            fill="black"/>
          <path
            d="M126.529 132.09L125.744 131.104L120.583 135.248L121.368 136.234L123.367 134.629L127.754 140.137L128.915 139.205L124.53 133.696L126.529 132.09Z"
            fill="black"/>
          <path
            d="M134.294 132.427L132.614 130.909L135.023 128.215L134.089 127.37L131.677 130.066L130.004 128.556L132.602 125.653L131.667 124.811L128.065 128.834L134.228 134.391L137.85 130.344L136.914 129.5L134.294 132.427Z"
            fill="black"/>
          <path
            d="M140.449 124.646L138.551 123.412L140.511 120.371L139.454 119.684L137.494 122.726L135.608 121.498L137.719 118.222L136.663 117.537L133.735 122.078L140.689 126.596L143.634 122.028L142.578 121.342L140.449 124.646Z"
            fill="black"/>
          <path
            d="M146.727 111.021C146.283 110.482 145.663 110.042 144.87 109.702C144.079 109.363 143.336 109.219 142.644 109.27C141.952 109.32 141.337 109.554 140.8 109.97C140.263 110.387 139.835 110.975 139.513 111.734L138.389 114.377L146.008 117.641L147.109 115.049C147.438 114.272 147.57 113.54 147.5 112.857C147.431 112.172 147.173 111.561 146.729 111.021H146.727ZM145.886 114.605L145.401 115.744L140.17 113.503L140.672 112.324C140.893 111.806 141.173 111.413 141.514 111.146C141.855 110.879 142.256 110.737 142.718 110.724C143.179 110.71 143.703 110.827 144.284 111.076C144.868 111.325 145.314 111.623 145.626 111.969C145.938 112.316 146.116 112.707 146.159 113.147C146.202 113.587 146.11 114.072 145.884 114.605H145.886Z"
            fill="black"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
