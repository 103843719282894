import React from "react";
import {Layout} from "antd";
import {ScrollRestoration, useOutlet} from "react-router-dom";
import {StyledLayout} from "./Layout.styles";
import {Header} from "../Header/Header";
import {Footer} from "../Footer/Footer";


export const PublicLayout = () => {
    const outlet = useOutlet();

    return (
        <StyledLayout>
            <ScrollRestoration />
            <Layout>
                <Header/>

                <Layout.Content>
                    {outlet}
                </Layout.Content>

                <Footer />
            </Layout>
        </StyledLayout>
    )
}