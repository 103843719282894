import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.0374 -0.0078125H9.0388C7.35675 -0.0078125 6 1.40564 6 3.158V46.8264C6 48.5787 7.35675 49.9922 9.0388 49.9922H40.9581C42.6401 49.9922 43.9969 48.5787 43.9969 46.8264V3.158C44.0762 1.40564 42.6401 -0.0078125 41.0374 -0.0078125ZM42.0768 3.158V8.32414H17.0365V2.07518H41.0374C41.6007 2.07518 42.0768 2.57113 42.0768 3.158ZM9.0388 2.07518H15.037V8.32414H7.99942V3.158C8.07876 2.57113 8.47547 2.07518 9.0388 2.07518ZM41.0374 47.9092H9.0388C8.47547 47.9092 7.99942 47.4132 7.99942 46.8264V10.4071H41.9974V46.8264C42.0768 47.4132 41.6007 47.9092 41.0374 47.9092Z" fill="#000000"/>
      <path d="M25.0421 7.32398C26.1608 7.32398 27.0415 6.40647 27.0415 5.24099C27.0415 4.07551 26.1608 3.158 25.0421 3.158C23.9233 3.158 23.0427 4.07551 23.0427 5.24099C23.0427 6.40647 23.9233 7.32398 25.0421 7.32398Z" fill="#000000"/>
      <path d="M31.0403 7.32398C32.159 7.32398 33.0397 6.40647 33.0397 5.24099C33.0397 4.07551 32.159 3.158 31.0403 3.158C29.9216 3.158 29.0409 4.07551 29.0409 5.24099C29.0409 6.40647 29.9216 7.32398 31.0403 7.32398Z" fill="#000000"/>
      <path d="M37.0386 7.32398C38.1573 7.32398 39.038 6.40647 39.038 5.24099C39.038 4.07551 38.1573 3.158 37.0386 3.158C35.9198 3.158 35.0392 4.07551 35.0392 5.24099C35.0392 6.40647 35.9198 7.32398 37.0386 7.32398Z" fill="#000000"/>
      <path d="M20.0832 31.2453C18.9645 31.2453 18.0838 32.1628 18.0838 33.3283C18.0838 34.4938 18.9645 35.4113 20.0832 35.4113C21.2019 35.4113 22.0826 34.4938 22.0826 33.3283C22.0826 32.1628 21.1226 31.2453 20.0832 31.2453Z" fill="#000000"/>
      <path d="M29.0409 32.3281C27.9222 32.3281 27.0415 33.2456 27.0415 34.4111C27.0415 35.5766 27.9222 36.4941 29.0409 36.4941C30.1596 36.4941 31.0403 35.5766 31.0403 34.4111C31.0403 33.2456 30.1596 32.3281 29.0409 32.3281Z" fill="#000000"/>
      <path d="M24.082 35.4113C22.9633 35.4113 22.0826 36.3288 22.0826 37.4943C22.0826 38.6597 22.9633 39.5772 24.082 39.5772C25.2008 39.5772 26.0814 38.6597 26.0814 37.4943C26.0814 36.3288 25.1214 35.4113 24.082 35.4113Z" fill="#000000"/>
      <path d="M25.0421 15.6559C17.8458 15.6559 12.0062 21.7396 12.0062 29.2367C12.0062 36.7338 17.9251 42.8174 25.0421 42.8174C32.159 42.8174 37.8399 36.9818 37.9986 29.65C38.2366 21.8966 32.3177 15.6477 25.0421 15.6477V15.6559ZM25.0421 17.7389C30.961 17.7389 35.7612 22.5744 35.9992 28.6581C33.2778 28.9887 27.9222 29.4929 25.4388 28.3274C21.9953 26.6578 17.4411 27.5753 13.9977 29.245C14.077 22.8307 18.9565 17.7472 25.0341 17.7472L25.0421 17.7389ZM25.0421 40.6601C19.7658 40.6601 15.283 36.7421 14.2436 31.4106C15.3623 30.8237 16.4017 30.4104 17.4411 30.0798C19.6786 29.4103 22.4793 29.0796 24.5581 30.1625C27.4382 31.5759 32.7144 31.1626 35.9198 30.7493C35.1978 36.3288 30.6436 40.6683 25.0421 40.6683V40.6601Z" fill="#000000"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
