import styled from 'styled-components'

import bg from '../../../../assets/images/section1/image-1.jpg'
import bgMiddle from '../../../../assets/images/section1/768-img.jpg'
import bgSoSmall from '../../../../assets/images/section1/image-so-small.jpg'
import bg1440 from '../../../../assets/images/section1/1440-img.jpg'
import bg768 from '../../../../assets/images/section1/768-img.jpg'
import {LightTheme} from "../../../../assets";

export const StyledSection1Container = styled.div`
  background-image: url(${bg});
  margin-top: 88px;
    background-position: top;
    
    @media (min-width:1920px) {
        background-size: cover;
        background-repeat: no-repeat;
    }

  @media(max-width: 576px) {
    margin-top: 72px;
  }
  
  .advantagesCard {
    width: 472px;
    margin-top: 80px;
    margin-bottom: 170px;
    

    .advantagesTitle {
      margin-top: 0;
      font-size: 40px;
      font-weight: bold;

      @media (max-width: 450px){
        font-size: 32px;
        line-height: 40px;
      }
      
      @media (max-width: 400px){
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
    
    @media(max-width: 400px){
      .ant-btn{
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .advantagesItem {
      padding-top: 8px;
      padding-bottom: 8px;

      .advantagesText {
        font-size: 18px;
        margin-left: 12px;
        color: ${LightTheme.colorText};
        
        @media (max-width: 400px){
          font-size: 14px !important;
        }
      }
      
      @media(max-width: 350px){
        svg{
          width: 18px !important;
          height: 18px !important;
        }
      }
      
    }

    @media (max-width: 460px){
      padding: 25px!important;
      margin-bottom: 50px !important;
    }
    
    @media (max-width: 400px){
      padding: 30px!important;
      margin-bottom: 50px !important;
    }
  }
  
  @media (max-width: 1200px) {
    background-image: url(${bg1440});
  }
  
  @media(max-width: 862px){
    .advantagesCard {
      padding: 30px;
      width: 364px;
      
      .advantagesTitle{
        font-size: 40px;
        line-height: 48px;
      }
      .ant-btn span{
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  
  @media(max-width: 862px){
    .advantagesText{
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  @media (max-width: 768px) {
    background-image: url(${bg768});
    background-repeat: no-repeat;
    
    
  }

  @media(max-width: 450px){
    background-image: url(${bgMiddle});
    background-position: center;

    .advantagesCard {
      position: relative;
      top: -20px;
      padding: 19px !important;
    }
  }

  @media(max-width: 400px){
    background-image: url(${bgSoSmall});
    background-repeat: no-repeat;
      background-position: initial;

    .advantagesCard {
      position: relative;
      top: 60px;
      margin-bottom: 80px;
      padding: 19px !important;
    }
  }
`