import Icon from "@ant-design/icons";


const Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="186" height="180" viewBox="0 0 186 180" fill="none">
    <path opacity="0.2" d="M46.094 169.889L18.3478 153.9L0.830078 164.011L28.4587 180L46.094 169.889Z" fill="#00549D"/>
    <path opacity="0.2"
          d="M60.3187 127.798C62.67 126.623 65.139 125.564 67.6079 124.742L128.391 152.135L185.999 118.863L126.862 84.7682L71.958 118.04C65.139 117.687 58.2024 117.217 51.3835 116.864C48.2091 116.629 44.7996 116.512 41.7428 117.335C38.9212 118.158 36.4523 119.686 33.9833 121.214C29.163 124.271 24.3427 128.386 21.8738 133.794C20.8156 135.911 20.6981 138.615 21.9913 140.613C22.6967 141.789 23.8724 142.612 24.9305 143.317C27.5171 145.081 29.986 146.962 32.5725 148.725C34.6887 150.254 36.805 151.665 39.1563 152.605C42.801 154.016 46.7983 153.781 50.6781 153.663C54.9105 153.546 59.143 153.311 63.2579 152.37C65.4917 151.9 67.7255 151.077 70.0769 150.724C72.8985 150.136 75.8377 150.136 78.6594 150.019C83.3621 149.901 88.0648 149.431 92.65 148.961C93.8257 148.843 95.119 148.49 95.4717 147.432C95.7068 146.374 94.7663 145.434 93.8257 144.846C89.946 142.612 85.1256 143.317 80.658 143.317C72.4282 143.2 63.9633 139.438 59.2606 132.736C58.6727 131.796 58.0849 130.737 58.4376 129.679C58.5551 128.856 59.4957 128.269 60.3187 127.798Z"
          fill="#00549D"/>
    <path
      d="M61.9657 74.3052L66.0806 77.2444C72.6644 77.2444 86.1848 78.0674 86.1848 78.0674V82.7701C76.6618 85.7093 65.7279 85.8269 61.7306 84.4161C60.6724 84.0634 59.3792 83.5931 58.0859 83.0053C60.6724 81.2417 61.9657 77.8322 61.9657 74.3052Z"
      fill="#FFB294"/>
    <path
      d="M82.1896 83.5916L87.3626 84.297L92.418 84.6497L97.591 83.2389C98.6491 82.8862 98.8843 82.1808 97.8262 82.2984L95.3572 82.5335L99.3546 81.1227C101.118 80.5348 100.883 79.7119 99.7073 79.7119L98.2964 79.8294L100.648 79.0064C102.059 78.6537 101.824 77.7132 100.765 77.5956L94.064 78.1835L100.295 76.5375C101.353 76.1848 101.118 75.2443 99.9424 75.2443L90.4194 76.5375L88.6558 75.4794L91.3599 72.4226C92.0653 71.5996 91.4775 70.6591 90.5369 71.4821C89.3612 72.5402 87.9504 73.4807 86.5396 74.1861L83.1301 78.4186L80.0733 80.5348L78.5449 82.0632L82.1896 83.5916Z"
      fill="#E68660"/>
    <path d="M128.393 59.9599V148.254L75.7227 117.921V29.5096L128.393 59.9599Z" fill="#779CBF"/>
    <path d="M75.7227 29.5098L128.393 59.9601L179.653 30.3328L126.983 0.00012207L75.7227 29.5098Z" fill="#F0FAFF"/>
    <path d="M179.652 30.334V118.628L128.393 148.255V59.9613L179.652 30.334Z" fill="white"/>
    <path
      d="M165.155 99.5012C172.844 89.2783 174.408 77.4789 168.648 73.1465C162.888 68.8141 151.985 73.5894 144.296 83.8124C136.607 94.0353 135.044 105.835 140.804 110.167C146.564 114.499 157.466 109.724 165.155 99.5012Z"
      fill="white"/>
    <path
      d="M166.372 100.919C174.061 90.6959 175.625 78.8965 169.865 74.5641C164.104 70.2318 153.202 75.007 145.513 85.23C137.824 95.4529 136.26 107.252 142.02 111.585C147.781 115.917 158.683 111.142 166.372 100.919Z"
      fill="#8ABDF1"/>
    <path
      d="M142.854 100.64C142.854 101.933 143.089 103.227 143.441 104.167C145.087 108.517 150.025 109.575 155.904 106.166C163.075 102.051 168.954 92.7629 168.954 85.5913C168.954 79.948 165.427 77.2439 160.489 78.1844C159.078 78.4196 157.55 79.0074 155.904 79.948C148.614 84.1804 142.854 93.3508 142.854 100.64Z"
      fill="#182939"/>
    <path
      d="M142.854 100.64C142.854 101.934 143.089 103.227 143.441 104.167C145.558 105.696 148.967 105.578 152.847 103.344C159.313 99.5823 164.604 91.2349 164.604 84.7687C164.604 81.2416 163.075 78.8902 160.606 78.1848C159.196 78.42 157.667 79.0078 156.021 79.9483C148.614 84.1808 142.854 93.3512 142.854 100.64Z"
      fill="#051117"/>
    <path opacity="0.75"
          d="M153.789 103.815C154.024 103.815 154.259 103.697 154.494 103.579C160.843 99.9347 166.133 91.5874 166.133 85.4738C166.133 84.6508 165.545 84.063 164.722 84.063C163.899 84.063 163.312 84.6508 163.312 85.4738C163.312 90.5293 158.491 98.0536 153.083 101.11C152.378 101.463 152.143 102.404 152.613 103.109C152.73 103.579 153.201 103.815 153.789 103.815Z"
          fill="white"/>
    <path opacity="0.2"
          d="M148.144 97.2305C148.732 97.2305 149.32 96.8778 149.438 96.2899C150.496 93.5858 152.142 90.8818 154.258 88.648C154.846 88.0601 154.728 87.1196 154.141 86.6493C153.553 86.0615 152.612 86.179 152.142 86.7669C149.79 89.3534 147.909 92.4102 146.734 95.3494C146.499 96.0548 146.851 96.8778 147.557 97.2305C147.792 97.1129 147.909 97.2305 148.144 97.2305Z"
          fill="white"/>
    <path opacity="0.5"
          d="M158.254 50.3202C158.842 49.9675 159.312 49.85 159.782 49.7324C160.488 49.6148 161.075 49.7324 161.546 49.9675L161.663 50.0851C161.781 50.0851 161.781 50.2027 161.898 50.2027L163.427 51.3784L162.604 52.554C162.486 54.9054 160.605 57.8446 158.254 59.2555C158.136 59.373 158.019 59.373 157.783 59.4906L156.725 61.019L154.727 59.4906C154.492 59.2555 154.256 58.9027 154.021 58.55C153.904 58.1973 153.786 57.8446 153.786 57.3744C153.904 54.7878 155.785 51.7311 158.254 50.3202Z"
          fill="#5EA6E9"/>
    <path
      d="M155.784 58.667C155.784 59.1373 155.902 59.49 156.019 59.8427C156.607 61.3711 158.253 61.6062 160.252 60.5481C162.721 59.1373 164.719 56.0805 164.719 53.6116C164.719 51.7305 163.544 50.7899 161.898 51.1426C161.427 51.2602 160.84 51.4954 160.369 51.7305C157.665 53.1413 155.784 56.1981 155.784 58.667Z"
      fill="#182939"/>
    <path
      d="M169.186 41.7367C169.421 41.6192 169.656 41.5016 169.892 41.5016C170.244 41.384 170.479 41.5016 170.715 41.6192C170.832 41.6192 170.832 41.7367 170.832 41.7367L171.538 42.207L171.185 42.6773C171.067 43.7354 170.244 45.0286 169.304 45.6165C169.186 45.6165 169.186 45.7341 169.069 45.7341L168.598 46.4395L167.658 45.7341C167.54 45.6165 167.423 45.4989 167.305 45.2638C167.305 45.1462 167.188 44.9111 167.188 44.7935C167.188 43.7354 168.01 42.3246 169.186 41.7367Z"
      fill="white"/>
    <path
      d="M168.011 45.381C168.011 45.6162 168.011 45.7337 168.128 45.8513C168.363 46.5567 169.069 46.6743 170.009 46.204C171.068 45.6162 172.008 44.2053 172.008 43.1472C172.008 42.3242 171.538 41.854 170.715 42.0891C170.48 42.0891 170.245 42.2067 170.009 42.3242C168.834 43.0296 168.011 44.3229 168.011 45.381Z"
      fill="#779CBF"/>
    <path
      d="M174.595 38.6806C174.83 38.563 175.066 38.4454 175.301 38.4454C175.653 38.3279 175.889 38.4454 176.124 38.563C176.241 38.563 176.241 38.6806 176.241 38.6806L176.947 39.1508L176.594 39.6211C176.476 40.6792 175.653 41.9725 174.713 42.5603C174.595 42.5603 174.595 42.6779 174.478 42.6779L174.007 43.3833L173.067 42.6779C172.949 42.5603 172.832 42.4428 172.714 42.2076C172.714 42.0901 172.597 41.8549 172.597 41.7374C172.714 40.6792 173.537 39.2684 174.595 38.6806Z"
      fill="white"/>
    <path
      d="M173.537 42.3249C173.537 42.56 173.537 42.6776 173.655 42.7951C173.89 43.5006 174.595 43.6181 175.536 43.1478C176.594 42.56 177.534 41.1492 177.534 40.0911C177.534 39.2681 177.064 38.7978 176.241 39.0329C176.006 39.0329 175.771 39.1505 175.536 39.2681C174.36 39.8559 173.537 41.2667 173.537 42.3249Z"
      fill="#779CBF"/>
    <path d="M150.259 53.4949L132.036 63.9585V75.9505L150.259 65.4869V53.4949Z" fill="#182939"/>
    <path opacity="0.5" d="M179.652 101.815V118.627L128.393 148.255V131.442L179.652 101.815Z" fill="#EDEDED"/>
    <path d="M134.389 66.0744L140.15 62.7825V65.7217L134.389 69.0136V66.0744Z" fill="white"/>
    <path opacity="0.5" d="M171.893 47.4971L168.602 49.4957V52.0822L171.893 50.0836V47.4971Z" fill="#5EA6E9"/>
    <path d="M173.068 46.7939V49.3805L176.36 47.4994V44.9128L173.068 46.7939Z" fill="#182939"/>
    <path opacity="0.5" d="M171.893 51.6128L168.602 53.4939V56.0804L171.893 54.1993V51.6128Z" fill="#5EA6E9"/>
    <path d="M173.068 50.9076V53.4941L176.36 51.613V49.0265L173.068 50.9076Z" fill="#779CBF"/>
    <path opacity="0.5" d="M171.893 55.61L168.602 57.6087V60.1952L171.893 58.1965V55.61Z" fill="#5EA6E9"/>
    <path d="M173.068 55.0232V57.6097L176.36 55.7286V53.1421L173.068 55.0232Z" fill="#779CBF"/>
    <path d="M171.893 59.7256L168.602 61.7243V64.3108L171.893 62.3121V59.7256Z" fill="white"/>
    <path d="M173.068 59.0205V61.607L176.36 59.7259V57.1394L173.068 59.0205Z" fill="white"/>
    <path
      d="M157.55 58.5493C157.55 58.902 157.55 59.1371 157.667 59.3723C158.02 60.4304 159.313 60.6655 160.724 59.8425C162.488 58.7844 163.899 56.6682 163.899 54.9046C163.899 53.6114 163.076 52.906 161.9 53.1411C161.547 53.2587 161.194 53.3763 160.842 53.6114C158.961 54.5519 157.55 56.7857 157.55 58.5493Z"
      fill="#CAE5FC"/>
    <path d="M125.335 63.2527V141.553L78.7783 114.63V36.2119L125.335 63.2527Z" fill="#182939"/>
    <path d="M121.81 67.6037V146.022L75.1357 125.095V46.7941L121.81 67.6037Z" fill="white"/>
    <path
      d="M40.9226 139.319C38.924 141.553 38.8064 145.903 39.3942 148.019C41.8632 149.548 44.097 150.136 45.8605 149.43C51.0335 150.136 53.7376 151.311 56.4417 151.076C59.3809 150.841 61.3796 150.371 63.3782 147.902C64.2012 146.844 63.8485 144.375 61.6147 143.669C57.3822 142.729 53.7376 141.671 51.8565 140.26L49.5051 133.088L41.9807 136.968L40.9226 139.319Z"
      fill="black"/>
    <path
      d="M37.2773 127.211L41.8625 131.678C41.8625 131.678 46.2125 133.324 43.8612 136.028C42.6855 137.321 39.3936 137.674 39.3936 137.674C36.2192 137.557 37.1598 137.792 30.6935 132.619L26.5786 131.208C24.3448 130.385 22.8164 128.974 22.8164 126.505L23.1691 124.859L26.6962 118.863L29.8705 118.981L37.2773 121.097V127.211Z"
      fill="black"/>
    <path
      d="M27.5195 121.214L35.2791 122.978C38.8061 122.625 41.9805 122.037 44.8021 121.45C47.7413 120.744 50.3278 120.039 52.4441 119.216C65.8469 114.043 65.7293 106.283 65.7293 106.283C63.7307 99.5818 43.9791 97.9359 36.925 97.5832C33.8682 97.348 31.8696 97.348 31.8696 97.348C32.3398 96.9953 27.6371 120.627 27.5195 121.214Z"
      fill="#0C2136"/>
    <path
      d="M61.6136 128.034L51.15 140.496C51.15 140.496 45.977 143.2 40.6864 139.438C41.0391 135.676 42.9202 131.561 45.154 128.269C47.3878 125.094 49.9743 122.508 51.8554 121.685C52.0905 121.567 52.2081 121.567 52.2081 121.567C52.0905 121.567 45.2716 124.389 37.6296 125.917C33.985 126.623 30.1052 126.976 26.5782 126.623C19.0538 125.682 17.643 116.629 17.5254 110.398C17.5254 106.871 17.8781 104.285 17.8781 104.285L29.5174 106.048L31.6336 106.401C31.6336 106.401 32.2215 106.401 33.162 106.519C33.7498 106.519 34.3377 106.636 35.1607 106.754C36.8066 106.871 38.8053 107.106 41.1567 107.459C45.6243 108.165 50.7973 109.223 55.3825 110.986C58.792 112.279 61.8487 114.043 63.8474 116.277C64.5528 117.1 65.2582 118.04 65.6109 118.981C65.6109 118.981 66.5515 123.684 61.6136 128.034Z"
      fill="#214B71"/>
    <path
      d="M55.7355 69.6019L62.0842 74.1871C62.0842 77.8317 60.7909 81.3588 58.0869 83.2399C55.0301 81.9466 51.503 80.3007 47.8584 78.4196L55.7355 69.6019Z"
      fill="white"/>
    <path
      d="M50.7974 90.1762C50.3272 90.9992 49.6218 91.8222 48.9164 92.5276L48.4461 93.9384L47.2704 95.5843L43.1555 103.932L41.0393 107.459C40.9217 107.576 40.4514 107.811 39.6284 108.282C36.6892 109.81 29.8702 113.102 23.6391 112.632C21.4053 112.514 19.2891 111.809 17.408 110.516C17.2904 109.105 15.8796 94.4087 22.4634 84.2978C29.2824 73.7166 35.7487 61.3719 51.738 67.603C51.738 67.603 51.9731 68.426 52.3258 69.7193C53.6191 73.9517 54.9123 83.4748 50.7974 90.1762Z"
      fill="#214B71"/>
    <path
      d="M32.2217 84.298C32.2217 84.298 38.688 77.8318 45.1542 80.6534C50.0921 82.8872 51.1502 88.2954 51.3854 90.7643C55.5003 84.0629 53.6192 73.8344 52.4435 69.4844C48.211 66.31 42.215 66.5452 40.569 67.6033C35.6312 71.7182 33.0447 77.9493 32.2217 84.298Z"
      fill="white"/>
    <path
      d="M48.9164 94.173L43.2731 103.696C36.8069 104.401 32.1041 105.107 30.1055 100.051C30.1055 100.051 30.1055 100.051 30.9284 93.1148C31.7514 86.1783 37.5123 80.7701 44.2137 83.2391C48.5637 84.885 49.034 90.5283 48.9164 94.173Z"
      fill="white"/>
    <path
      d="M60.9078 74.305C60.7902 74.5401 53.7361 83.1227 53.7361 83.1227C53.7361 83.1227 48.2104 78.655 48.5631 70.3077C49.9739 68.5441 51.1496 67.6036 53.6185 65.0171L60.9078 74.305Z"
      fill="#FFB294"/>
    <path
      d="M51.5014 66.3106C51.5014 66.3106 50.4433 57.1403 59.1434 54.5538C59.1434 54.5538 69.3719 53.2605 71.4881 61.9606C71.4881 61.9606 72.1935 67.6039 69.607 70.0728L69.7246 72.0715C69.7246 72.5417 69.4894 73.012 69.0192 73.2472L68.0786 73.7174L66.4327 76.4215C65.8448 77.4796 64.6691 78.185 63.4934 78.185C62.3178 78.3026 60.7894 78.0675 59.1434 77.0094C55.2636 74.7756 51.5014 66.3106 51.5014 66.3106Z"
      fill="#FFB294"/>
    <path
      d="M71.3719 65.8393C69.4908 68.0732 63.6124 66.5448 61.8488 70.3069C61.8488 70.3069 58.9096 74.1867 55.0299 71.7178C51.0325 69.2488 50.7974 67.3677 50.7974 67.3677C50.7974 67.3677 50.2096 57.8447 55.7353 55.1406C60.7907 52.6717 64.788 53.9649 67.9624 56.0812C71.1367 58.0798 74.1935 62.4299 71.3719 65.8393Z"
      fill="black"/>
    <path
      d="M60.908 68.545C60.5553 68.545 60.2026 68.7801 59.8499 69.0152C59.0269 69.7206 58.5566 70.7788 58.5566 71.8369C58.5566 72.3072 58.7918 72.895 59.2621 73.1301C59.6148 73.3653 59.9675 73.2477 60.3202 73.1301C60.6729 73.0126 60.908 72.6599 61.1432 72.4247C61.6134 71.8369 62.3188 70.8963 62.3188 70.0733C62.4364 69.2504 61.8486 68.4274 60.908 68.545Z"
      fill="#FFB294"/>
    <path opacity="0.5"
          d="M63.8473 116.159L60.5554 116.629L47.2702 118.51L26.4605 112.984L23.874 112.279C26.343 110.868 28.2241 108.517 29.7525 105.813C33.0444 99.699 34.1025 92.4098 34.1025 92.4098L39.5106 97.9355L48.3283 95.4666L43.3904 103.814L41.2742 107.341C45.6242 108.046 50.9148 109.105 55.5 110.986C58.7919 112.279 61.8487 113.925 63.8473 116.159Z"
          fill="#214B71"/>
    <path
      d="M45.9775 105.695C49.8573 104.872 53.3844 102.05 54.56 98.1705L60.7912 108.869L82.5414 107.929L82.8941 113.69C82.8941 113.69 69.3737 118.627 61.7317 117.334C61.7317 117.334 57.4993 117.452 50.4451 110.045L45.9775 105.695Z"
      fill="#FFB294"/>
    <path
      d="M46.0942 106.283L37.0414 97.5825C27.9887 88.6473 40.8036 77.9486 47.9753 87.7068L55.6173 98.9934C54.4416 103.108 50.0916 105.342 46.0942 106.283Z"
      fill="white"/>
    <path
      d="M79.6007 113.573L84.6561 114.749L89.7116 115.572L95.0022 114.631C96.1778 114.279 96.413 113.691 95.3549 113.691L92.8859 113.808L97.0008 112.75C98.7643 112.28 98.6468 111.575 97.4711 111.457H96.0603L98.4117 110.869C99.8225 110.634 99.7049 109.693 98.6468 109.576H91.9454L98.2941 108.518C99.4698 108.283 99.2346 107.342 98.0589 107.224L88.4183 107.695L86.7724 106.401L89.7116 103.58C90.417 102.874 89.9467 101.816 88.8886 102.639C87.5953 103.58 86.1845 104.403 84.6561 104.991L82.4223 108.047L77.602 110.752L75.9561 112.162L79.6007 113.573Z"
      fill="#FFB294"/>
    <path
      d="M91.0076 159.777L90.5373 158.484L90.4198 158.367C90.3022 158.367 90.1846 158.249 90.0671 158.367L88.5387 158.602C88.4211 158.602 88.4211 158.602 88.4211 158.602L88.6562 159.307V160.13C88.6562 160.248 88.4211 160.365 88.3035 160.365C87.9508 160.365 87.4806 160.248 87.2454 160.012C86.54 159.66 86.0697 159.072 85.9522 158.484V158.014C85.8346 157.896 85.717 157.779 85.5995 157.661L73.8426 151.783C73.6075 151.665 73.1372 151.548 72.7845 151.665C71.9615 151.783 71.1385 151.665 70.4331 151.312C70.3156 151.195 70.198 151.195 70.0804 151.077C69.7277 150.842 69.4926 150.372 69.375 149.902V149.079C69.375 148.608 69.6101 148.021 70.198 147.668C70.3155 147.55 70.5507 147.55 70.6683 147.668C70.6683 147.668 70.7858 147.668 70.7858 147.785L71.4912 149.079L71.6088 149.196C71.7264 149.196 71.8439 149.314 72.0791 149.196L73.6075 148.843L73.2548 148.138V147.315C73.2548 147.198 73.3723 147.08 73.6075 147.08C74.0777 147.198 74.4304 147.315 74.7832 147.433C75.4886 147.785 75.9588 148.373 75.9588 148.961V149.549C76.0764 149.666 76.194 149.784 76.3115 149.902L87.9508 155.78C88.186 155.898 88.6562 156.015 89.009 155.898C89.5968 155.898 90.1846 155.898 90.7725 156.015C91.0076 156.133 91.2427 156.133 91.4779 156.25C92.1833 156.603 92.4184 157.073 92.536 157.661V158.484C92.536 159.072 92.1833 159.66 91.5955 160.012C91.3603 159.777 91.1252 159.777 91.0076 159.777Z"
      fill="#4D4D4D"/>
    <path
      d="M87.1261 159.19C87.4788 159.307 87.8315 159.425 88.1842 159.542C88.4193 159.542 88.6545 159.425 88.5369 159.307L88.0667 158.014C88.0667 157.896 88.1842 157.779 88.4194 157.661L89.9477 157.426C90.0653 157.426 90.1829 157.426 90.3004 157.426C90.418 157.426 90.418 157.544 90.418 157.544L90.8883 158.837C90.8883 158.837 90.8883 158.954 91.0059 158.954C91.1234 158.954 91.3586 159.072 91.4761 158.954C92.6518 158.131 92.7694 156.838 91.3586 156.133C91.1234 156.015 91.0059 155.898 90.6532 155.898C90.0653 155.78 89.4775 155.662 88.8896 155.78C88.5369 155.78 88.0667 155.78 87.8315 155.662L76.1922 149.784C75.8395 149.666 75.7219 149.431 75.7219 149.079C75.8395 148.373 75.3692 147.785 74.5463 147.315C74.1936 147.08 73.7233 146.962 73.3706 146.962C73.1354 146.962 72.9003 147.08 73.0179 147.198L73.7233 148.373C73.8408 148.491 73.7233 148.608 73.4881 148.726L71.9597 149.079C71.8422 149.079 71.607 149.079 71.4895 149.079L71.3719 148.961L70.6665 147.668C70.6665 147.668 70.6665 147.668 70.5489 147.55C70.4314 147.55 70.1962 147.433 70.0787 147.55C68.903 148.256 69.0205 149.549 69.8435 150.254C69.9611 150.372 70.0787 150.372 70.1962 150.489C70.7841 150.842 71.7246 150.96 72.5476 150.842C72.9003 150.842 73.3706 150.842 73.6057 150.96L85.3626 156.838C85.7153 156.956 85.8328 157.191 85.8328 157.426C85.8328 158.014 86.3031 158.719 87.1261 159.19Z"
      fill="#CCCCCC"/>
    <path
      d="M97.9441 156.368C97.9441 155.898 98.2968 155.193 98.767 154.958L99.4725 154.487C99.7076 154.37 99.9427 154.37 100.06 154.487L79.9561 142.848C79.8385 142.73 79.6034 142.73 79.3682 142.848L78.6628 143.318C78.1925 143.553 77.8398 144.141 77.8398 144.729C77.8398 144.964 77.9574 145.199 78.075 145.199L98.1792 156.839C98.0616 156.839 97.9441 156.603 97.9441 156.368Z"
      fill="#BD8B55"/>
    <path
      d="M90.5355 152.137C90.5355 151.549 90.8882 150.961 91.3584 150.608L92.1814 150.138C92.4166 150.02 92.6517 150.138 92.7693 150.255C92.7693 150.138 92.7693 150.138 92.6517 150.02L79.7191 142.496C79.6016 142.378 79.3664 142.378 79.1313 142.613L78.3083 143.084C77.8381 143.319 77.4854 144.024 77.4854 144.612C77.4854 144.847 77.6029 145.082 77.7205 145.2L90.5355 152.607C90.653 152.724 90.8882 152.607 90.8882 152.607C90.7706 152.489 90.8882 152.607 90.7706 152.489C90.4179 152.372 90.5355 152.254 90.5355 152.137Z"
      fill="#E34248"/>
    <path d="M99.2332 160.836L96.4116 160.718L94.7656 159.777V156.838L96.4116 157.779L99.2332 157.896V160.836Z"
          fill="#4F4F4F"/>
    <path d="M99.2314 157.898L106.05 154.018V156.957L99.2314 160.837V157.898Z" fill="#585858"/>
    <path d="M96.4116 157.778L94.7656 156.838L102.878 152.135L106.052 154.016L99.2332 157.896L96.4116 157.778Z"
          fill="#373737"/>
    <path
      d="M102.059 158.719C101.589 158.954 101.236 158.719 101.236 158.248C101.236 157.778 101.589 157.073 102.059 156.838L102.765 156.367C103.235 156.132 103.588 156.367 103.588 156.838C103.588 157.308 103.235 158.013 102.765 158.248L102.059 158.719Z"
      fill="#BD8B55"/>
    <path d="M29.3995 177.764L3.76955 162.833L1.65332 155.073L27.2833 170.005L29.3995 177.764Z" fill="#A32126"/>
    <path d="M27.2833 170.005L1.65332 155.074L18.3481 145.434L43.978 160.365L27.2833 170.005Z" fill="#C93B40"/>
    <path d="M43.9779 160.365L41.9793 170.476L29.3994 177.765L27.2832 170.006L43.9779 160.365Z" fill="#C1272D"/>
    <path d="M27.2833 170.004L1.65332 155.072L3.65198 144.962L29.2819 159.893L27.2833 170.004Z" fill="#C1272D"/>
    <path d="M29.2803 159.894L3.65039 144.962L16.2302 137.791L41.9777 152.604L29.2803 159.894Z" fill="#FF3B42"/>
    <path d="M41.9763 152.604L43.975 160.364L27.2803 170.005L29.2789 159.894L41.9763 152.604Z" fill="#FF3B42"/>
    <path d="M17.1738 166.124L13.0589 163.773L12.3535 161.304L16.4684 163.655L17.1738 166.124Z" fill="#999999"/>
    <path d="M16.4684 163.656L12.3535 161.304L13.0589 158.012L17.1738 160.364L16.4684 163.656Z" fill="white"/>
    <path
      d="M27.634 144.257L21.7556 140.847C21.0502 140.494 20.4623 140.494 20.1096 140.73L18.9339 141.435C18.4637 141.67 18.2285 142.258 18.2285 142.963V145.785L19.7569 146.726L20.9326 146.02V143.669L26.4583 146.843C26.9286 147.078 27.2813 147.666 27.2813 148.254V151.076L28.8097 152.016L29.9854 151.311V148.489C29.9854 146.843 28.9273 144.962 27.634 144.257Z"
      fill="#4D4D4D"/>
    <path
      d="M26.4593 144.962C27.7525 145.668 28.8107 147.549 28.8107 149.077V151.899L27.2823 150.958V148.137C27.2823 147.667 26.9296 146.961 26.4593 146.726L20.5809 143.316C20.1106 143.081 19.7579 143.316 19.7579 143.787V146.608L18.2295 145.668V142.846C18.2295 141.318 19.2876 140.73 20.5809 141.553L26.4593 144.962Z"
      fill="#192936"/>
    <path
      d="M84.6589 136.734L95.3577 142.848C95.3577 142.848 95.4753 142.965 95.4753 143.083C95.4753 143.318 95.3577 143.671 95.1226 143.788C95.005 143.906 94.8874 143.906 94.8874 143.788L84.1887 137.675L82.7779 137.204L81.7197 136.146L82.3076 135.206L83.7184 135.676L84.6589 136.734Z"
      fill="#999999"/>
    <path
      d="M102.765 145.67L95.4757 142.025C95.3581 141.908 95.1229 141.908 94.7702 142.143C94.3 142.378 93.9473 143.084 93.9473 143.671C93.9473 143.907 94.0648 144.142 94.1824 144.259L101.001 148.727C100.766 148.609 100.649 148.257 100.649 147.904C100.649 147.081 101.237 146.14 101.942 145.67C102.177 145.553 102.53 145.553 102.765 145.67Z"
      fill="#FF3B42"/>
    <path
      d="M103.117 146.492C103.117 145.669 102.529 145.317 101.824 145.787C101.118 146.14 100.53 147.198 100.53 148.021C100.53 148.844 101.118 149.196 101.824 148.726C102.529 148.256 103.117 147.315 103.117 146.492Z"
      fill="#A32126"/>
    <path d="M81.6016 136.146L82.8948 137.087L83.4826 135.911L82.1894 135.206L81.6016 136.146Z" fill="#E6E6E6"/>
    <path
      d="M83.6009 137.321L82.8955 137.086L83.6009 135.91L84.1888 136.381C84.4239 136.616 84.4239 136.851 84.3063 137.086C84.1888 137.321 83.8361 137.439 83.6009 137.321Z"
      fill="#B3B3B3"/>
  </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
