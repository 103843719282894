import Icon from "@ant-design/icons";


const Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#D7B56D"/>
    <path
      d="M11.2178 14.9244C12.4978 17.44 14.56 19.5022 17.0756 20.7822L19.0311 18.8267C19.28 18.5778 19.6267 18.5067 19.9378 18.6044C20.9333 18.9333 22 19.1111 23.1111 19.1111C23.3469 19.1111 23.573 19.2048 23.7397 19.3715C23.9064 19.5382 24 19.7643 24 20V23.1111C24 23.3469 23.9064 23.573 23.7397 23.7397C23.573 23.9064 23.3469 24 23.1111 24C19.1034 24 15.2598 22.4079 12.4259 19.5741C9.59206 16.7402 8 12.8966 8 8.88889C8 8.65314 8.09365 8.42705 8.26035 8.26035C8.42705 8.09365 8.65314 8 8.88889 8H12C12.2357 8 12.4618 8.09365 12.6285 8.26035C12.7952 8.42705 12.8889 8.65314 12.8889 8.88889C12.8889 10 13.0667 11.0667 13.3956 12.0622C13.4933 12.3733 13.4222 12.72 13.1733 12.9689L11.2178 14.9244Z"
      fill="black"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
