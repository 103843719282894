import styled from "styled-components";
import {Col} from "antd";

export const StyledPaymentSection = styled(Col)`
    padding-top: 32px;
    padding-bottom: 32px;
    
    @media (max-width: 768px) {
        position: relative;
        top: -39px;
    }

    @media (max-width: 400px) {
        position: relative;
        top: 50px;
    }
    
    .textContainer{
        justify-content: end;
    }
    
    .about{
        margin-bottom: 24px;
        
        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: #fff;
            letter-spacing: 1px;
        }
        
        @media(max-width: 1600px){
            margin-bottom: 0 !important;
            span{
                font-size: 32px;
                line-height: 48px;
            }
        }

        @media(max-width: 1272px){
            span{
                font-size: 28px;
                line-height: 44px;
            }
        }

        @media(max-width: 1010px){
            span{
                font-size: 24px;
                line-height: 44px;
            }
        }

        @media (max-width: 767px) {
            padding-top: 20px;
            padding-bottom: 20px;
            
            span{
                text-align: center !important;
            }
        }

        @media(max-width: 728px){
            span{
                font-size: 20px;
                line-height: 36px;
            }
        }

        @media(max-width: 628px){
            span{
                font-size: 20px;
                line-height: 32px;
            }
        }

        @media(max-width: 490px){
            span{
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    
    .ant-btn{
        height: 80px;
        background-color: #FFC439;
        padding: 28px 50px;

       &-icon{
           position: relative;
           bottom: 3px;
       }
        
        .content{
            position: relative;
            bottom: 2px;
        }
        
        .text {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 1px;
            color: #1D1D1F;
        }
        
        .anticon{
            width: 20%;
            padding-left: 10px;
            padding-right: 10px;
        }
        
      &:hover{
          background-color: #FF9900 !important;
      }

        @media (max-width: 1260px) {
            .text{
                font-size: 16px;
            }

            .anticon{
                width: 20%;
            }
        }

        @media (max-width: 1200px) {
            height: 120px;
            padding: 21px;

            .content {
                flex-direction: column;
            }
            
            .textContainer{
                margin-bottom: 24px;
                
                .text{
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .anticon{
                padding-left: 12px;
                padding-right: 12px;
                width: 20%;
            }
            
            .iconsRow{
                width: 80%;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }

        @media (max-width: 495px) {
            .anticon{
                padding-left: 6px !important;
                padding-right: 6px !important;
                width: 19%;
            }
        }
        
        @media (max-width: 340px) {
            .text{
                font-size: 14px !important;
            }
        }
        
    }
`