import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.902 0H8.09802C7.50291 0 7 0.495131 7 1.08104V48.919C7 49.5049 7.50291 50 8.09802 50H41.902C42.4971 50 43 49.5049 43 48.919V1.08104C43 0.495131 42.4971 0 41.902 0ZM9.19604 2.07955H23.9816V47.8379H9.19604V2.07955ZM40.8878 47.8379H26.1022V2.07955H40.8878V47.8379Z" fill="#000000"/>
    <path d="M18.6675 6.23865H14.4431C13.848 6.23865 13.3451 6.73379 13.3451 7.31969V18.716C13.3451 19.3019 13.848 19.797 14.4431 19.797H18.6675C19.2626 19.797 19.7655 19.3019 19.7655 18.716V7.31969C19.7655 6.73379 19.2626 6.23865 18.6675 6.23865ZM17.6533 17.7174H15.5411V8.3182H17.6533V17.7174Z" fill="#000000"/>
    <path d="M30.3267 26.0439C30.9218 26.0439 31.4247 25.5488 31.4247 24.9629V9.39924C31.4247 8.81334 30.9218 8.3182 30.3267 8.3182C29.7315 8.3182 29.2286 8.81334 29.2286 9.39924V25.0371C29.2286 25.5405 29.7315 26.0356 30.3267 26.0356V26.0439Z" fill="#000000"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
