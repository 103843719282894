import React from "react";
import {StyledAreaSection2} from "./Section2.styles";
import {Col, Row, Grid} from "antd";

import arrFile from "../../../../assets/data/areas.json"

const {useBreakpoint} = Grid;

export const Section2 = ({setIsModalOpen}) => {
  
  const screens = useBreakpoint();

  const arrOfService = arrFile
  
  function splitIntoColumns(addressList, numColumns) {
    const length = addressList.length;
    const perColumn = Math.ceil(length / numColumns);
    const columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push(addressList.slice(i * perColumn, (i + 1) * perColumn));
    }
    return columns;
  }

  return (
    <StyledAreaSection2>
      <Col xs={{
        pull: 1,
        span: 22,
        push: 1,
      }} lg={{
        pull: 3,
        span: 18,
        push: 3,
      }} xl={{
        pull: 4,
        span: 16,
        push: 4,
      }}>
          {arrOfService.map((item, index) => {
            const numCols = screens.md ? 3 : 2;
            const columns = splitIntoColumns(item.addressList, numCols)
            
            return (
                <Col span={24} key={`area-container-${index}`}>
                  <h1 className="h1-text" style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", marginTop: 80, marginBottom: 60}}>{item.regionName}</h1>
                  <Row justify="start">
                    {columns.map((colAddresses, colIndex) => (
                      <Col
                        key={`column-${colIndex}`}
                        xs={12}
                        md={8}
                        style={{ position: 'relative' }}
                      >
                        {colAddresses.map((addressItem, addressIndex) => (
                          <div key={`address-${addressIndex}`}>
                            <span className="areaName">{addressItem}</span>
                          </div>
                        ))}
                      </Col>
                    ))}
                  </Row>
                </Col>
            )
          })}
      </Col>
    </StyledAreaSection2>
  )
}