import React from "react";
import {Button, Col, Divider, Row} from "antd";
import {StyledPrivacyPolicy} from "./index.styles";
import {InfoIcon} from "../../assets";
import {useNavigate} from "react-router-dom";
import {MyBackButton} from "../../components/common/MyBackButton/MyBackButton";
import {MyButton} from "../../components/common/MyButton/MyButton";

export const PrivacyPolicy = () => {

  const navigate = useNavigate();

  return (
    <StyledPrivacyPolicy>
      <Col xs={{
        pull: 1,
        span: 22,
        push: 1,
      }} lg={{
        pull: 3,
        span: 18,
        push: 3,
      }} xl={{
        pull: 4,
        span: 16,
        push: 4,
      }}>
        <div>

          <Row className="h1-row" justify="center" align="middle" style={{position: "relative"}}>
            <h1 className="h1-text" style={{textAlign: "center", marginRight: "auto", marginLeft: "auto"}}>Privacy policy</h1>
          </Row>


          <Row className="infoContainer" align="middle">
            <Col>
              <InfoIcon/>
              <span className="infoText">Type of website: Appliance repair shop advertising Effective date: 1st day of January, 2024</span>
            </Col>
          </Row>

          <Row className="aboutSite" style={{flexDirection: "column"}}>
            <Col>
              <span><Button type="link" onClick={() => navigate("/")}>aurusrepair.com</Button> (the "Site") is owned and operated by Aurus Appliance Repair Inc. Aurus Appliance</span>
            </Col>
            <Col>
              <span>Repair Inc can be contacted at:</span>
            </Col>
            <Col>
              <span>service@aurusrepair.com</span>
            </Col>
            <Col>
              <span>(704) 803-4900</span>
            </Col>
            <Col>
              <span>10820 Boykin Place #409 Matthews NC 28105</span>
            </Col>
          </Row>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Purpose</span>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <p>The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following:</p>
              <p>1. The personal data we will collect;</p>
              <p>2. Use of collected data;</p>
              <p>3. Who has access to the data collected; and</p>
              <p>4. The rights of Site users.</p>
            </Col>
            <Col className="extraText">
              <span className="termsText">This Privacy Policy applies in addition to the terms and conditions of our Site.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Consent</span>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <p>By using our Site users agree that they consent to:</p>
              <p>1. The conditions set out in this Privacy Policy; and</p>
              <p>2. The collection, use, and retention of the data listed in this Privacy Policy.</p>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Personal Data We Collect</span>
            </Col>
            <Col>
              <span className="termsText">We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without notifying you first.</span>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <p>We may also collect the following data when you perform certain functions on our Site:</p>
              <p>1. First and last name;</p>
              <p>2. Email address;</p>
              <p>3. Phone number; and</p>
              <p>4. Address.</p>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <p>This data may be collected using the following methods:</p>
              <p>1. Submitting service request form.</p>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">How We Use Personal Data</span>
            </Col>
            <Col>
              <span className="termsText">Data collected on our Site will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Site. We will not use your data beyond what we disclose in this Privacy Policy.</span>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <p>The data we collect when the user performs certain functions may be used for the following purposes:</p>
              <p>1. Create service work order.</p>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Who We Share Personal Data With</span>
            </Col>
            <Col>
              <h4>Employees</h4>
              <span className="termsText">We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.</span>
            </Col>
            <Col className="termsTitleContainer listContainer">
              <h4>Other Disclosures</h4>
              <p>We will not sell or share your data with other third parties, except in the following cases:</p>
              <p>1. If the law requires it;</p>
              <p>2. If it is required for any legal proceeding;</p>
              <p>3. To prove or protect our legal rights;</p>
              <p>4. To buyers or potential buyers of this company in the event that we seek to sell the company.</p>
            </Col>
            <Col className="extraText">
              <span className="termsText">If you follow hyperlinks from our Site to another Site, please note that we are not responsible for and have no control over their privacy policies and practices.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">How Long We Store Personal Data</span>
            </Col>
            <Col>
              <span className="termsText">User data will be stored until the purpose the data was collected for has been achieved. You will be notified if your data is kept for longer than this period.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">How We Protect Your Personal Data</span>
            </Col>
            <Col>
              <span className="termsText">In order to protect your security, we use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination.</span>
            </Col>
            <Col className="extraText">
              <span className="termsText">While we take all reasonable precautions to ensure that user data is secure and that users are protected,there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Children</span>
            </Col>
            <Col>
              <span className="termsText">We do not knowingly collect or use personal data from children under 13 years of age. If we learn that we have collected personal data from a child under 13 years of age, the personal data will be deleted as soon as possible. If a child under 13 years of age has provided us with personal data their parent or guardian may contact our privacy officer.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">How to Access, Modify, Delete, or Challenge the Data Collected</span>
            </Col>
            <Col>
              <span className="termsText">If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, or if you would like your data to be deleted or modified in any way, please contact our privacy officer here:</span>
            </Col>
            <Row className="aboutSite" style={{flexDirection: "column"}}>
              <Col>
                <span>Ruslan Pateev</span>
              </Col>
              <Col>
                <span>service@aurusrepair.com</span>
              </Col>
              <Col>
                <span>(704) 803-4900</span>
              </Col>
              <Col>
                <span>10820 Boykin Place #409 Matthews NC 28105</span>
              </Col>
            </Row>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Do Not Track Notice</span>
            </Col>
            <Col>
              <span className="termsText">Do Not Track ("DNT") is a privacy preference that you can set in certain web browsers. We do not track the users of our Site over time and across third party websites and therefore do not respond to browser-initiated DNT signals.</span>
            </Col>
          </div>

          <Divider/>

          <div>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Modifications</span>
            </Col>
            <Col>
              <span className="termsText">This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.</span>
            </Col>
          </div>

          <Divider/>

          <div style={{paddingBottom: 65}}>
            <Col className="termsTitleContainer">
              <span className="termsTitle">Contact Information</span>
            </Col>
            <Col>
              <span className="termsText">If you have any questions, concerns or complaints, you can contact our privacy officer, Ruslan Pateev, at:</span>
            </Col>
            <Row className="aboutSite" style={{flexDirection: "column"}}>

              <Col>
                <span>service@aurusrepair.com</span>
              </Col>
              <Col>
                <span>(704) 803-4900</span>
              </Col>
              <Col>
                <span>10820 Boykin Place #409 Matthews NC 28105</span>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </StyledPrivacyPolicy>
  )
}