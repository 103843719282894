import Icon from "@ant-design/icons";


const Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM17.2685 9.88384L10.8069 16.3454C10.7212 16.4312 10.6194 16.4993 10.5073 16.5457C10.3953 16.5922 10.2752 16.6161 10.1538 16.6161C10.0325 16.6161 9.91243 16.5922 9.80037 16.5457C9.68831 16.4993 9.5865 16.4312 9.50077 16.3454L6.73154 13.5762C6.55834 13.4029 6.46103 13.168 6.46103 12.9231C6.46103 12.6781 6.55834 12.4432 6.73154 12.27C6.90475 12.0968 7.13967 11.9995 7.38462 11.9995C7.62957 11.9995 7.86449 12.0968 8.0377 12.27L10.1538 14.3873L15.9623 8.57769C16.0481 8.49193 16.1499 8.4239 16.2619 8.37748C16.374 8.33107 16.4941 8.30718 16.6154 8.30718C16.7367 8.30718 16.8568 8.33107 16.9688 8.37748C17.0809 8.4239 17.1827 8.49193 17.2685 8.57769C17.3542 8.66345 17.4223 8.76527 17.4687 8.87733C17.5151 8.98938 17.539 9.10948 17.539 9.23077C17.539 9.35205 17.5151 9.47216 17.4687 9.58421C17.4223 9.69627 17.3542 9.79808 17.2685 9.88384Z"
      fill="#D7B56D"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
