import React, {useState} from "react";
import {Col, Flex, Row, Grid} from "antd";
import {StyledSection6Container} from "./Section6.styles";
import {
    LGIcon,
    GEIcon,
    SpeedQueenIcon,
    ElectroluxGroupIcon, FrigidaireIcon, DacorIcon, HisenseIcon, MediaIcon, MonogramIcon, ZLineIcon
} from "../../../../assets";


import arrFile from "../../../../assets/data/arrayOfBrands.json"
import {MyButton} from "../../../common/MyButton/MyButton";

const {useBreakpoint} = Grid;

export const Section6 = () => {

    const screens = useBreakpoint();

    const brandIcons = [
        <LGIcon/>, <GEIcon/>, <SpeedQueenIcon/>, <ElectroluxGroupIcon/>, <FrigidaireIcon/>, <DacorIcon/>,
        <HisenseIcon/>, <MediaIcon/>, <ZLineIcon />, <MonogramIcon/>,
    ]

    const arrOfBrands = arrFile.slice(1)

    const adaptiveBrands = arrFile.slice(1).slice(145)

    const [arr, setArr] = useState(adaptiveBrands);
    const [showBtn, setShowBtn] = useState(true);

    return (
        <StyledSection6Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{marginTop: 99, marginBottom: 57}} align="middle">
                    <h1 className="title">We service all brands and manufacturers:</h1>
                </Row>
                <Row justify="start">
                    {!screens.lg ? arr.map((item, index) => (
                      <Col style={{position: 'relative'}} key={index + "-brand-item"} xs={12} sm={8} lg={6} xl={4} >
                          <Row>
                              <div className="circle"/>
                              <span className="brandName">{item}</span>
                          </Row>
                      </Col>
                    )) : arrOfBrands.map((item, index) => (
                        <Col style={{position: 'relative'}} key={index + "-brand-item"} xs={8} lg={6} xl={4} >
                            <Row>
                                <div className="circle"/>
                                <span className="brandName">{item}</span>
                            </Row>
                        </Col>
                    ))}

                    {!screens.lg && showBtn ? <Row justify="center" className="showMoreButton"><MyButton type="primary" onClick={() => {
                        setArr(arrOfBrands)
                        setShowBtn(!showBtn)
                    }}>Show more</MyButton></Row> : null}
                </Row>

            </Col>
        </StyledSection6Container>
    )
}