import styled from 'styled-components'

export const StyledSection10Container = styled.div`
  margin-top: 40px;
  position: relative;
  background-color: #fff;
  
  .ant-select-selector{
    border: 1px solid #CECED2!important;

    .ant-select-selection-placeholder{
      color: #1D1D1F !important;
      font-size: 16px !important;
    }
  }
    
    .ant-input{
    border: 1px solid #CECED2!important;
    }

  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;

      @media (max-width: 495px){
          margin-top: 40px !important;
          margin-bottom: 20px !important;
      }

    .title{
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;

      @media (max-width: 470px){
        font-size: 40px;
        line-height: 40px;
      }
      
      @media (max-width: 361px){
        font-size: 32px;
        line-height: 36px;
      }
    }

    .subtitle{
      font-size: 18px;
      line-height: 28px;
      text-align: center;

      @media (max-width: 470px){
        font-size: 16px;
        line-height: 28px;
      }

        @media (max-width: 361px){
            font-size: 14px;
            line-height: 28px;
        }
    }
      
    
  }

    .contactUsImage {
        position: absolute;
        right: 0;
        top: 177px;

        @media (max-width: 1457px) {
            right: -120px;
        }

        @media (max-width: 1075px) {
            top: 203px;
        }

        @media (max-width: 991px) {
            right: 0;
        }

        @media (max-width: 819px) {
            top: 230px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }
    .contactUsImageMobile{
        display: none;
        margin-top: 32px;
        
        @media (max-width: 767px) {
            display: block;
            position: relative;
        }
    }
`