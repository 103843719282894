import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.919 0H8.08104C7.49513 0 7 0.503383 7 1.08104V48.919C7 49.5049 7.49513 50 8.08104 50H41.919C42.5049 50 43 49.5049 43 48.919V1.08104C43 0.495131 42.5049 0 41.919 0ZM40.9205 2.07955V7.31969H9.16207V2.07955H40.9205ZM9.16207 47.8379V9.39924H40.9205V47.8379H9.16207Z" fill="#000000"/>
      <path d="M24.7257 19.8052C18.9822 19.8052 14.3279 24.4677 14.3279 30.203C14.3279 35.9383 18.9904 40.6008 24.7257 40.6008C30.461 40.6008 35.1234 35.9383 35.1234 30.203C35.1234 24.4677 30.461 19.8052 24.7257 19.8052ZM24.7257 38.5295C20.154 38.5295 16.4075 34.783 16.4075 30.2113C16.4075 25.6395 20.154 21.8931 24.7257 21.8931C29.2974 21.8931 33.0439 25.6395 33.0439 30.2113C33.0439 34.783 29.2974 38.5295 24.7257 38.5295Z" fill="#000000"/>
      <path d="M24.7257 24.9629C21.8952 24.9629 19.4856 27.29 19.4856 30.203C19.4856 33.116 21.8127 35.4431 24.7257 35.4431C27.6387 35.4431 29.9658 33.116 29.9658 30.203C29.9658 27.29 27.5562 24.9629 24.7257 24.9629ZM24.7257 33.2811C22.9762 33.2811 21.5651 31.8699 21.5651 30.1205C21.5651 28.371 22.9762 26.9599 24.7257 26.9599C26.4752 26.9599 27.8863 28.371 27.8863 30.1205C27.8863 31.8699 26.4752 33.2811 24.7257 33.2811Z" fill="#000000"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
